
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import * as React from 'react';

const carouselProps = {
  className: 'venues-list-carousel',
  isPlaying: true,
  dragEnabled: true,
  touchEnabled: false,
  naturalSlideWidth: 2,
  naturalSlideHeight: 1,
};

const VenueSlide = ({ venue, index }) => (
  <Slide index={index}>
    <div className='venues-list-carousel__wrapper'>
      <img className='venues-list-carousel__img' src={`/static/img/venues/logos/${venue.logo}`} alt={venue.name} />
    </div>
  </Slide>
);

export default ({ venues }) => (
  <CarouselProvider {...({ totalSlides: venues.length, ...carouselProps })}>
    <Slider>
      {venues.map( (venue, index) => <VenueSlide key={index} venue={venue} index={index} /> )}
    </Slider>
  </CarouselProvider>
);
