
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import * as React from 'react';
import './Hero.scss';

const carouselProps = {
  className: 'hero__carousel',
  isPlaying: true,
  dragEnabled: false,
  touchEnabled: false,
  naturalSlideWidth: 500,
  naturalSlideHeight: 600,
  totalSlides: 3,
};

export default React.forwardRef((_props, ref: any) => (
  <div ref={ref} className='large-row hero slider-container'>
    <div className='hero__content'>
      <h1 className='hero__title'>Never let the experience stop</h1>
      <h3 className='hero__subtitle'>Spend more time enjoying the moment. Let us handle the ordering.</h3>
      <a className='btn btn--outline' href='/download'>Get the Noble app</a>
    </div>
    <CarouselProvider {...carouselProps}>
      <Slider>
        <Slide index={0}>
          <div className='hero__carousel__img' style={{ backgroundImage: 'url(/static/img/hero/hero1@2x.jpg)' }} />
        </Slide>
        <Slide index={1}>
          <div className='hero__carousel__img' style={{ backgroundImage: 'url(/static/img/hero/hero2@2x.jpg)' }} />
        </Slide>
        <Slide index={2}>
          <div className='hero__carousel__img' style={{ backgroundImage: 'url(/static/img/hero/hero3@2x.jpg)' }} />
        </Slide>
      </Slider>
    </CarouselProvider>
  </div>
));
