import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { RichText } from 'prismic-reactjs';
import { format } from 'date-fns';
import { consumerNav } from '../../constants/NavLinks';
import { Navbar, Footer } from '../../components';
import './Press.scss';

const PressArticle = ({ article }) => {
  return (
    <article className='press-article'>
      <a href={`/press-article/${article._meta.uid}`}>
        <h1 className='press-article__title'>{RichText.asText(article.title)}</h1>
      </a>
      <p className='press-article__subtitle'>
        {RichText.asText(article.source)} • {format(new Date(article.date), 'MMMM D, YYYY')}
      </p>
      {RichText.asText(article.preview)}
      <p className='press-article__read-more'><a href={`/press-article/${article._meta.uid}`}>Read More</a></p>
    </article>
  );
};

const PRESS_QUERY = gql`
query PressArticlesQuery {
  allPress_articles(sortBy:date_DESC) {
    edges {
      node {
        _meta {
          uid
        }
        title
        source
        date
        preview
        content
        _linkType
      }
    }
  }
}
`;

export const Press = () => {
  const page = 1;
  const { data } = useQuery(PRESS_QUERY, {
    variables: {
      page,
    },
  });
  const pressArticles = data ? data.allPress_articles.edges.map(node => node.node) : null;

  return (
    <div>
      <Navbar links={consumerNav.links} cta={consumerNav.cta} passedHero={true} />
      <div className='hero press-hero'>
        <div className='press-hero__content'>
          <h1 className='press-hero__title'>Newsroom</h1>
          <h3 className='press-hero__subtitle'>
            Stay up-to-date with feature launches, partnerships, and the latest stories featuring Noble.
          </h3>
        </div>
      </div>
      <section className='press-articles'>
        {pressArticles && pressArticles.map( (article, index) => <PressArticle key={index} article={article} /> )}
        <ul className='press-articles-pagination'>
          {page > 1 && <li><a href={`/press/${page - 1}`}>{'<'}</a></li>}
          <li className='press-articles-pagination__selected'><a href={`/press/${page}`}>{page}</a></li>
          <li><a href={`/press/${page + 1}`}>{page + 1}</a></li>
          <li><a href={`/press/${page + 2}`}>{page + 2}</a></li>
          <li><a href={`/press/${page + 1}`}>{'>'}</a></li>
        </ul>
      </section>
      <Footer />
    </div>
  );
};
