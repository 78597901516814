
import * as React from 'react';

export default () => (
  <section className='venues-join'>
    <img src='/static/img/case-studies/phones.png' className='hidden--md' />
    <div className='venues-join__content'>
      <h3 className='venues-join__header'>Join the Noble life</h3>
      <p>
        Ready to get started? Order your Noble kit and begin to see how mobile ordering can enhance the guest
        experience at your venue.
      </p>
      <p className='venues-join__buttons'>
        <a href='#get-in-touch' className='hidden--sm btn btn--small-padding btn--purple-gradient venues-join__button'>
          Get the Noble Kit
        </a>
        <a href='#get-in-touch' className='show--sm btn btn--white venues-join__button'>Get the Noble Kit</a>
      </p>
      <img src='/static/img/venues/venue-list-iphone@2x.png' className='show--sm venues-join__phone' />
    </div>
  </section>
);
