
import * as React from 'react';
import './Rewards.scss';

export default () => (
  <div className='large-row rewarding' id='benefits'>

    <div className='rewarding__heading'>
      <img className='rewarding__logo' role='presentation' src='/static/img/crown/crown.svg' />
      <h1 className='rewarding__title'>Noble makes the process rewarding</h1>
    </div>

    <div className='rewarding__columns'>

      <div className='rewarding__col'>
        <div className='rewarding__img'>
          <img role='presentation' src='/static/img/rewarding/skip-icon.svg' />
        </div>

        <div className='rewarding__subheading'>
          Avoid the Wait
        </div>

        <div className='rewarding__caption'>
          Skip waiting in lines or get it delivered right to you without waiting for your server. Make the most of your
          time out and focus on enjoying the event.
        </div>
      </div>

      <div className='rewarding__col'>
        <div className='rewarding__img'>
          <img role='presentation' src='/static/img/rewarding/credit-card-icon.svg' />
        </div>
        <div className='rewarding__subheading'>
          Go Cashless
        </div>
        <div className='rewarding__caption'>
          Never worry about having cash for ordering food and drinks. Order, pay, and tip right from your phone.
          No more cash, cards, tabs, or leaving your credit card behind.
        </div>
      </div>

      <div className='rewarding__col'>
        <div className='rewarding__img'>
          <img role='presentation' src='/static/img/rewarding/specials-icon.svg' />
        </div>

        <div className='rewarding__subheading'>
          Exclusive Specials
        </div>

        <div className='rewarding__caption'>
          Find all of your favorite drinks and food in the Noble app. Get exclusive drink specials only available as a
          perk to Noble users.
        </div>
      </div>

    </div>
  </div>
);
