
import * as React from 'react';

const fps = 10; // 60;
const interval = 1000 / fps;

export const withNavControl = (Component) => {
  return class NavContainer extends React.Component<any, { navPassedHero: boolean }> {
    public then = Date.now();
    public now;
    public delta;
    public heroRef;

    constructor(props) {
      super(props);

      this.state = { navPassedHero: false };

      this.animate = this.animate.bind(this);
      this.heroRef = React.createRef();
    }

    componentDidMount() {
      this.animate();
    }

    animate() {
      window.requestAnimationFrame( this.animate );

      this.now = Date.now();
      this.delta = this.now - this.then;

      // if enough time has elapsed, render the next frame
      if ( this.delta > interval && this.heroRef.current ) {

        // Get ready for next frame by setting then=now, but...
        // Also, adjust for fpsInterval not being multiple of 16.67
        this.then = this.now - ( this.delta % interval );

        // Actual rendering
        // const navY = this.navRef.current.offsetTop + this.navRef.current.offsetHeight;
        const heroY = this.heroRef.current.offsetTop + this.heroRef.current.offsetHeight - 100;
        // console.log(navY, heroY);
        this.setState({ navPassedHero: window.pageYOffset > heroY });
      }
    }

    render() {
      return (
        <Component heroRef={this.heroRef} navPassedHero={this.state.navPassedHero} {...this.props} />
      );
    }
  };
};
