
import * as React from 'react';
import { consumerNav } from '../../constants/NavLinks';
import { Footer } from '../Footer';
import { Navbar } from '../Navbar';
import { withNavControl } from '../../hocs';
import './Layout.scss';

const Layout = ({ navPassedHero, heroRef, title, children }) => (
  <div>
    <Navbar links={consumerNav.links} cta={consumerNav.cta} passedHero={navPassedHero} />
    <div ref={heroRef} className='legal-hero hero hero-sm'>
      <div className='hero__content'>
        <h1 className='hero__title'>{title}</h1>
      </div>
    </div>
    <div className='legal-content'>
      {children}
    </div>
    <Footer />
  </div>
);

export const LegalLayout = withNavControl(Layout);
