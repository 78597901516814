
import { CarouselInjectedProps, Slide, Slider } from 'pure-react-carousel';
import * as React from 'react';
import './PurposefulVenues.scss';
import PurposefulVenuesNavIcon from './PurposefulVenuesNavIcon';

interface VenueType {
  icon: string;
  title: string;
  description: string;
}

const venueTypes: VenueType[] = require('./data.json');

interface PurposefulVenueCarouselProps extends CarouselInjectedProps {
  readonly currentSlide: number;
}

const BG_PATH = '/static/img/venues/types';
const pathForType = venueType => `url(${BG_PATH}/${venueType.background})`;

const PurposefulVenueSlide = ({ index, venueType }) => (
  <Slide index={index}>
    <div key={index} className='purposeful-venue-type' style={{ backgroundImage: pathForType(venueType) }}>
      <h4 className='purposeful-venue-type__header'>{venueType.title}</h4>
      <p className='purposeful-venue-type__desc'>{venueType.description}</p>
    </div>
  </Slide>
);

export class PurposefulVenueCarousel extends React.Component<PurposefulVenueCarouselProps, {}> {
  constructor(props) {
    super(props);

    this.onClickVenueType = this.onClickVenueType.bind(this);
  }

  onClickVenueType(venueType) {
    const index = venueTypes.indexOf(venueType);
    this.props.carouselStore.setStoreState({ currentSlide: index });
  }

  renderIcons() {
    const { currentSlide } = this.props;
    return venueTypes.map( (venueType, index) => (
      <PurposefulVenuesNavIcon
        showArrow={index === currentSlide}
        onClick={this.onClickVenueType}
        venueType={venueType}
        key={index}
      />
    ));
  }

  render() {
    return (
      <section className='purposeful'>
        <h2 className='header header--lg header--purple'>Made for venues with a purpose</h2>
        <div className='purposeful-nav-wrapper hidden--sm'>
          <div className='purposeful-nav'>
            {this.renderIcons()}
          </div>
        </div>
        <Slider>
          {venueTypes.map( (type, index) => <PurposefulVenueSlide key={index} index={index} venueType={type} /> )}
        </Slider>
      </section>
    );
  }
}
