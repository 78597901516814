
import classNames from 'classnames';
import dateFns from 'date-fns';
import * as React from 'react';

interface CalendarDayTileProps {
  currentMonth: any;
  day: any;
  value: any;
  onSelect: (day) => void;
}

export default class CalendarDayTile extends React.Component<CalendarDayTileProps, {}> {
  onSelect = () => {
    const cloneDay = this.props.day;
    this.props.onSelect(dateFns.parse(cloneDay));
  }

  render() {
    const { currentMonth, day, value } = this.props;
    const monthStart = dateFns.startOfMonth(currentMonth);

    const className = classNames('calendar-col', 'calendar-cell', {
      disabled: !dateFns.isSameMonth(day, monthStart) || dateFns.isPast(day),
      selected: dateFns.isSameDay(day, value),
    });

    const dateFormat = 'D';
    const formattedDate = dateFns.format(day, dateFormat);

    return (
      <div className={className} key={day.toString()} onClick={this.onSelect}>
        <span className='number'>{formattedDate}</span>
      </div>
    );
  }
}
