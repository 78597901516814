import dateFns from 'date-fns';
import React from 'react';
import './Calendar.scss';
import CalendarDayTile from './CalendarDayTile';

class Calendar extends React.Component<any, any> {
  state = {
    currentMonth: new Date(),
  };

  renderHeader() {
    const dateFormat = 'MMMM YYYY';
    const hidden = this.state.currentMonth.getMonth() <= (new Date()).getMonth();

    return (
      <div className='calendar-header calendar-row flex-middle'>
        <div className='calendar-col calendar-col-start'>
          { !hidden && <div className='icon' onClick={this.prevMonth}>
            chevron_left
          </div>}
        </div>
        <div className='calendar-col calendar-col-center'>
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className='calendar-col calendar-col-end' onClick={this.nextMonth}>
          <div className='icon'>chevron_right</div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = 'ddd';
    const days: JSX.Element[] = [];

    const startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className='calendar-col calendar-col-center' key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>,
      );
    }

    return <div className='calendar-days calendar-row'>{days}</div>;
  }

  renderCells() {
    const { value } = this.props;
    const { currentMonth } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const rows: JSX.Element[] = [];

    let days: JSX.Element[] = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        days.push(
          <CalendarDayTile day={day} currentMonth={currentMonth} value={value} onSelect={this.props.onSelect} />,
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className='calendar-row' key={day.toString()}>
          {days}
        </div>,
      );
      days = [] as JSX.Element[];
    }
    return <div className='calendar-body'>{rows}</div>;
  }

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    });
  }

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    });
  }

  render() {
    return (
      <div className='calendar'>
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default Calendar;
