
import { CarouselProvider, WithStore } from 'pure-react-carousel';
import * as React from 'react';
import { PurpleDotCarouselNav } from '../../../components';
import Carousel from './Carousel';
import './Carousel.scss';

const carouselProps = {
  isPlaying: true,
  dragEnabled: true,
  touchEnabled: true,
  naturalSlideWidth: 1280,
  naturalSlideHeight: 521,
  totalSlides: 4,
};

const Decorated = WithStore(Carousel, state => ({
  currentSlide: state.currentSlide,
})) as any;

const slides = [
  {
    title: 'NIB',
    image: '/static/img/kit/noble-in-a-box-image-1.jpg',
  },
  {
    title: 'NIB',
    image: '/static/img/kit/noble-in-a-box-image-2.jpg',
  },
  {
    title: 'NIB',
    image: '/static/img/kit/noble-in-a-box-image-3.jpg',
  },
  {
    title: 'NIB',
    image: '/static/img/kit/noble-in-a-box-image-4.jpg',
  },
];

export class CarouselContainer extends React.Component<any, any> {
  render() {
    return (
      <CarouselProvider ref={this.props.heroRef} {...carouselProps}>
        <Decorated slides={slides} />
        <PurpleDotCarouselNav data={slides} className='kit-carousel-dots' />
      </CarouselProvider>
    );
  }
}
