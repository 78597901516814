
import classNames from 'classnames';
import * as React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Logo } from '../Logo';
import './Navbar.scss';

interface NavLink {
  title: string;
  href: string;
}

interface NavbarProps {
  links: NavLink[];
  cta: NavLink;
  passedHero: boolean;
}

const MobileNavLink = ({ link, mobile }) => {
  const className = mobile ? 'mobile-nav__link' : 'nav__link';
  if (link.href.startsWith('#')) {
    return (
      <li className={className}>
        <AnchorLink offset='100' href={link.href}>{link.title}</AnchorLink>
      </li>
    );
  } else {
    return (
      <li className={className}>
        <a href={link.href}>{link.title}</a>
      </li>
    );
  }
};

export class Navbar extends React.Component<NavbarProps, { open: boolean }> {
  constructor(props) {
    super(props);
    this.state = { open: false };

    this.onClickHamburger = this.onClickHamburger.bind(this);
  }

  onClickHamburger() {
    this.setState({ open: !this.state.open });
  }

  render() {
    const { passedHero, links, cta } = this.props;
    const { open } = this.state;

    return (
      <div>
        <a className='covid-banner' href='https://grubbrr.com/noble'>
          We’ve been acquired, learn more here
        </a>
        <div className={classNames('nav', { 'sticky': passedHero, 'mobile--open': open })} role='navigation'>
          <a href='/' className='logo'>
            <Logo className='nav__svg' />
          </a>

          <div className='nav__hamburger show--md'>
            <button className='hamburger' onClick={this.onClickHamburger}>
              <span className='hamburger__container'>
                <span className='hamburger__dash' />
              </span>
            </button>
          </div>

          <ul className='nav__links hidden--sm'>
            {links.map( (link, index) => <MobileNavLink key={index} mobile={false} link={link} /> )}
            <div className='nav__cta'>
              <a className={classNames('btn', { 'btn--white': !passedHero, 'btn--blue': passedHero })} href={cta.href}>
                {cta.title}
              </a>
            </div>
          </ul>
        </div>
        <div className={classNames('mobile-nav', { 'is--open': open })}>
          <ul onClick={this.onClickHamburger} className='mobile-nav__links'>
            {links.map( (link, index) => <MobileNavLink key={index} mobile={true} link={link} /> )}
            <div className='mobile-nav__cta'>
              <a className='btn btn--blue' href={cta.href}>{cta.title}</a>
            </div>
          </ul>
        </div>
      </div>
    );
  }
}
