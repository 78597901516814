
import * as React from 'react';
import { ResponsiveImage } from '../ResponsiveImage';

interface PurposefulVenuesNavIconProps {
  venueType: any;
  onClick: (VenueType) => void;
  showArrow: boolean;
}

class PurposefulVenuesNavIcon extends React.Component<PurposefulVenuesNavIconProps, any> {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.venueType);
  }

  render() {
    const { venueType } = this.props;
    const { title, icon } = venueType;

    return (
      <div role='button' onClick={this.onClick} className='purposeful-nav__item'>
        <div className='purposeful-nav__icon-wrapper'>
          <ResponsiveImage
            className='purposeful-nav__icon'
            basePath='/img/venues'
            fileName={icon}
            extension='png'
            alt={title}
          />
        </div>
        {this.props.showArrow && <div className='purposeful-nav__triangle' />}
      </div>
    );
  }
}

export default PurposefulVenuesNavIcon;
