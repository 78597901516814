
import classnames from 'classnames';
import { Dot } from 'pure-react-carousel';
import * as React from 'react';
import './PurpleDotCarouselNav.scss';

interface PurpleDotCarouselNavProps {
  data: any[];
  className?: string;
}

export const PurpleDotCarouselNav = ({ data, className }: PurpleDotCarouselNavProps) => (
  <div className={classnames('purple-dots', className)}>
    {data.map( (_, index) => <Dot key={index} className='purple-dots__dot' slide={index}>&nbsp;</Dot> )}
  </div>
);
