
import { RichText } from 'prismic-reactjs';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { venueNav } from '../../constants/NavLinks';
import LinkResolver from '../../core/linkResolver';
import { Navbar, Footer } from '../../components';
import { withNavControl } from '../../hocs/withNavControl';
import '../../styles/index.scss';
import './CaseStudy.scss';

const getHeroStyles = caseStudy => {
  const url = caseStudy.hero_image && caseStudy.hero_image.url;
  return (url) ? { backgroundImage: `url(${url})` } : {};
};

const getCSS = (row, field) => row[field] && { backgroundImage: `url(${row[field].url})` };

const CASE_STUDY_QUERY = gql`
query CaseStudyQuery($id: String) {
  allCase_studys(uid: $id) {
    edges {
      node {
        title
        preview_text
        location
        noble_setup
        capacity
        event
        list_preview_image
        how_did_noble_help
        hero_image
        large_detail
        small_detail
        small_detail_2
        challenge
        _linkType
      }
    }
  }
}
`;

const CaseStudyContent = ({ heroRef, caseStudy }) => {
  return (
    <div>
      <div ref={heroRef} className='hero hero-sm case-study-hero' style={getHeroStyles(caseStudy)}>
        <div className='hero__content'>
          <h1 className='hero__title'>{RichText.asText(caseStudy.title, LinkResolver)}</h1>
          <h3 className='hero__subtitle'>
            <b>Location:</b> {RichText.asText(caseStudy.location, LinkResolver)} &bull;&nbsp;
            <b>Capacity:</b> {RichText.asText(caseStudy.capacity, LinkResolver)} <br />
            <b>Noble Setup:</b> {RichText.asText(caseStudy.noble_setup, LinkResolver)} &bull;&nbsp;
            <b>Event:</b> {RichText.asText(caseStudy.event, LinkResolver)}
          </h3>
        </div>
      </div>
      <section className='container case-study-section'>
      <h3 className='header header--lg header--purple'>What's the challenge?</h3>
        <div className='case-study-copy'>
          {RichText.render(caseStudy.challenge, LinkResolver)}
        </div>
      </section>
      <section className='case-study-images'>
      <div className='case-study-images-large' style={getCSS(caseStudy, 'large_detail')} />
      <div className='case-study-images-right'>
        <div className='case-study-images-right-img' style={getCSS(caseStudy, 'small_detail')} />
        <div className='case-study-images-right-img' style={getCSS(caseStudy, 'small_detail_2')} />
      </div>
      </section>
      <section className='container case-study-section'>
      <h3 className='header header--lg header--purple'>How did Noble help?</h3>
      <div className='case-study-copy'>
        {RichText.render(caseStudy.how_did_noble_help, LinkResolver)}
      </div>
      </section>
    </div>
  );
}

export const CaseStudy = withNavControl(({ heroRef, navPassedHero, match }) => {
  const { id } = match.params;
  const { data } = useQuery(CASE_STUDY_QUERY, {
    variables: {
      id,
    },
  });

  const caseStudy = data ? data.allCase_studys.edges.map(node => node.node)[0] : null;

  return (
    <div>
      <Navbar links={venueNav.links} cta={venueNav.cta} passedHero={navPassedHero} />
      {caseStudy && <CaseStudyContent heroRef={heroRef} caseStudy={caseStudy} />}
      <Footer />
    </div>
  );
});
