
import * as React from 'react';
import { Logo } from '../LogoStacked';
import Email from './Email';
import './Footer.scss';
import Tweet from './Tweet';

const tweets = [
  'Is the Lost Spirits Distillery Tour the Wackiest in the World? http://scq.io/ncq3V2f4  via @Liquor',
  'Is the Lost Spirits Distillery Tour the Wackiest in the World? http://scq.io/ncq3V2f4  via @Liquor',
];

const links = [
  {
    href: 'https://getnoble.co/',
    title: 'Home',
  },
  {
    href: '/about',
    title: 'About',
  },
  {
    href: '/venues',
    title: 'Venues',
  },
  {
    href: '/press',
    title: 'Press',
  },
  {
    href: 'mailto:hello@getnoble.co',
    title: 'Contact Us',
  },
  {
    href: '/privacy-policy',
    title: 'Privacy Policy',
  },
  {
    href: '/terms-of-service',
    title: 'Terms Of Use',
  },
  {
    href: 'https://venue.getnoble.co',
    title: 'Venue Login',
  },
];

const FooterLink = ({ link }) => (
  <div className='site-map__item'><a className='site-map__link' href={link.href}>{link.title}</a></div>
);

export const Footer = () => (
  <footer className='footer'>
    <div className='footer-content'>
      <div className='site-map'>
        <Logo className='footer__logo' />
        <div className='site-map__grid'>
          {links.map( (link, index) => <FooterLink key={index} link={link} /> )}
        </div>
      </div>
      <div className='twitter-wrapper'>
        <div className='twitter'>
          <h1 className='footer__heading'>Follow us on Twitter</h1>
          <div className='tweets'>
            {tweets.map( (tweet, index) => <Tweet key={index} tweet={tweet} /> )}
          </div>
        </div>
      </div>
      <Email />
    </div>
    <p className='footer__copy'>Copyright &copy; {(new Date()).getFullYear()} GetNoble Inc</p>
  </footer>
);
