
import * as React from 'react';
// import "./index.scss";

export default () => (
  <div id='experience'>
    <div className='large-row two-col-lockup two-col-lockup__vertical--align'>
      <div className='two-col-lockup__image two-col-lockup__image--centered two-col-lockup__vertical--align'>
        <img alt='Noble: Easy Order, Easy Pay' className='img' src='/static/img/easy-order-easy-pay-iphones@2x.png' />
      </div>
      <div className='venues__copy' id='about'>
        <h1 className='venues__title'>
          Easier ordering.<br />Happier guests.
        </h1>
        <p>
          Noble makes it simple for patrons to easily browse a venue’s menu, customize their order, pay, and pickup or
          have the item delivered right to them.
        </p>
        <p>
          No hassles. Just happy customers and more repeat orders with premium sales.
        </p>
      </div>
    </div>
    <div className='large-row two-col-lockup two-col-lockup__vertical--align'>
      <div className='two-col-lockup__copy' id='about'>
        <h1 className='venues__title'>
          Unlock the<br />Network Advantage
        </h1>
        <p>
          Noble consists of a network of leading entertainment venues that share in driving guest awareness and
          adoption of the Noble experience.
        </p>
        <p>
          Noble’s growing user base is always seeking entertainment and expect a trusted and standard ordering
          experience across venues.
        </p>
      </div>

      <div className='two-col-lockup__image two-col-lockup__image--centered two-col-lockup__vertical--align'>
        <img alt='Noble: Easy Order, Easy Pay' className='img' src='/static/img/venues/bar-shot.jpg' />
      </div>
    </div>
  </div>
);
