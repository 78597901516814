
import classnames from 'classnames';
import * as React from 'react';
import ReactDOM from 'react-dom';
import NoSSR from 'react-no-ssr';
import { Dropdown } from '../../../components';
import './OrderForm.scss';

// declare let paypal;
declare let fbq;

const UNIT_PRICE = 695;
const QUANTTIY_OPTIONS = ['1', '2', '3', '4', '5'];
const INDUSTRIES = [
  'Concerts',
  'Comedy Clubs',
  'Stadiums & Arenas',
  'Theatres',
  'Mutli-Purpose Venues',
];

interface OrderFormState {
  quantity: null | string;
  color: 'black' | 'white';
  industry: null | string;
  submitted: boolean;
  errors: string[];
}

const ColorButton = ({ title, selected, onClick }) => (
  <button onClick={onClick} className={classnames('btn', { 'btn--selected': selected })}>
    {title}
  </button>
);

const PayPalStyles = { size: 'medium', color: 'blue' };

const client = {
  sandbox: 'AZojQYOwTqWQz14nmZb9KoUekLYNqiyZBrkM9kyzbk9r_bYk_1gWWpkV2zEg2IyK3p6fbnWc92ARdmRa',
  production: 'AatldnVXejpEQT_l9ogDt2iNvbBOSq9Mgl9vOeX2hKusG8oC5gfcjuSd65ycaHLs0IyYSydkMrQdPOrc',
};

const PayPalButton = ({ quantity, color, onAuthorize }) => {

  const payment = (_data, actions) => {
    return actions.payment.create({
      transactions: [
        {
          reference_id: `kit-${color}-${quantity}`,
          description: `Noble Kit - ${color} x ${quantity}`,
          amount: {
            total: UNIT_PRICE * quantity,
            currency: 'USD',
          },
        },
      ],
    });
  };

  const PayPal = (window as any).paypal && (window as any).paypal.Button.driver('react', { React, ReactDOM });
  if (!PayPal) {
    return null;
  }

  const props = {
    env: 'production',
    style: PayPalStyles,
    client,
    payment,
    commit: true,
    onAuthorize,
  };

  return <PayPal {...props} />;
};

export class OrderForm extends React.PureComponent<any, OrderFormState> {
  public onClickBlack;
  public onClickWhite;

  constructor(props) {
    super(props);

    this.state = { quantity: null, color: 'black', industry: null, submitted: false, errors: [] };
  }

  onAuthorize = async (_data, actions) => {
    return actions.payment.execute().then(() => {
      fbq('track', 'Purchase', {value: (Number(this.state.quantity) || 1) * UNIT_PRICE, currency: 'USD'});
      this.setState({ submitted: true });
    });
  }

  onClickColor = (color) => {
    this.setState({ color });
  }

  onChangeQuantity = ({ value: quantity }) => {
    const errors = this.state.errors.filter( error => error !== 'quantity' );
    this.setState({ quantity, errors });
  }

  onChangeIndustry = ({ value: industry }) => {
    const errors = this.state.errors.filter( error => error !== 'industry' );
    this.setState({ industry, errors });
  }

  updateErrors = () => {
    const errors: string[] = [];
    if (!this.state.quantity) {
      errors.push('quantity');
    }

    if (!this.state.industry) {
      errors.push('industry');
    }

    this.setState({ errors });
  }

  render() {
    const { quantity, industry, color, submitted, errors } = this.state;
    if (submitted) {
      return (
        <div className='order-form'>
          <p className='order-form__confirmation'>
            Thank you for your purchase!<br />You'll receive an email shortly with more information on your kit.
          </p>
        </div>
      );
    }

    const price = (Number(quantity) || 1) * UNIT_PRICE;
    const industryDropdownProps = {
      options: INDUSTRIES,
      value: industry,
      onChange: this.onChangeIndustry,
      placeholder: 'Select your industry',
      hasError: errors.includes('industry'),
    };

    const quantityDropdownProps = {
      options: QUANTTIY_OPTIONS,
      value: quantity,
      onChange: this.onChangeQuantity,
      placeholder: 'QTY',
      hasError: errors.includes('quantity'),
    };

    return (
      <div className='order-form'>
        <p className='order-form__price'>${price}</p>
        <div className='order-form__quantity'>
          <Dropdown {...quantityDropdownProps} />
        </div>
        <div className='order-form__industry'>
          <Dropdown {...industryDropdownProps} />
        </div>
        <div className='order-form__color'>
          <ColorButton title='Black' selected={color === 'black'} onClick={this.onClickBlack} />
          <ColorButton title='White' selected={color === 'white'} onClick={this.onClickWhite} />
        </div>
        <NoSSR>
          <div className='order-form__button-wrapper'>
            {(!quantity || !industry) && <div className='order-form__button-overlay' onClick={this.updateErrors} />}
            <PayPalButton color={color} quantity={quantity} onAuthorize={this.onAuthorize} />
          </div>
        </NoSSR>
      </div>
    );
  }
}
