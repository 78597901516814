
/* tslint:disable:rule1 max-line-length */
import * as React from 'react';

const svg = (className) => `
  <svg class="${className}" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="203.56" height="142.95" viewBox="0 0 203.56 142.95">
    <defs>
        <style>.cls-1,.cls-2{fill:#fff;}.cls-2{fill-rule:evenodd;}</style>
    </defs>
    <g>
        <path class="cls-2" d="M203.56,113.98v4.14h-24.96v-36.76h24.49v4.14h-19.83v11.91h17.24v3.88h-17.24v12.68h20.3Zm-64.13,4.14v-36.76h4.66v32.62h20.5v4.14h-25.16Zm-38.76-32.72v12.27h11.7c.83,0,1.59-.16,2.28-.49,.69-.33,1.29-.77,1.78-1.32,.5-.55,.9-1.2,1.19-1.94,.29-.74,.44-1.53,.44-2.36s-.14-1.67-.42-2.43c-.28-.76-.64-1.41-1.11-1.97-.47-.55-1.03-.98-1.71-1.29-.67-.31-1.39-.47-2.15-.47h-12.01Zm18.4,20.01c-.29-.78-.69-1.46-1.19-2.04-.5-.59-1.1-1.05-1.79-1.4s-1.45-.52-2.28-.52h-13.15v12.63h12.79c.86,0,1.65-.17,2.38-.52,.72-.35,1.36-.8,1.91-1.37,.55-.57,.98-1.23,1.29-1.99,.31-.76,.47-1.55,.47-2.38s-.15-1.63-.44-2.41Zm4.3,7.12c-.57,1.17-1.34,2.17-2.33,3-.98,.83-2.12,1.47-3.42,1.92-1.29,.45-2.68,.67-4.17,.67h-17.45v-36.76h18.27c1.28,0,2.43,.28,3.47,.83,1.03,.55,1.92,1.27,2.64,2.15,.72,.88,1.28,1.88,1.68,3,.4,1.12,.6,2.25,.6,3.39,0,1.83-.46,3.52-1.37,5.08-.91,1.55-2.22,2.71-3.91,3.47,2.1,.62,3.77,1.77,5,3.44,1.23,1.67,1.84,3.65,1.84,5.93,0,1.41-.28,2.71-.86,3.88Zm-71.53-7.38c.6,1.74,1.46,3.29,2.56,4.63,1.1,1.34,2.43,2.42,3.98,3.21,1.55,.79,3.28,1.19,5.18,1.19s3.73-.42,5.28-1.27c1.55-.84,2.87-1.95,3.96-3.31,1.09-1.36,1.91-2.91,2.49-4.63,.57-1.73,.85-3.47,.85-5.23,0-1.86-.3-3.67-.9-5.41-.6-1.74-1.47-3.28-2.59-4.61-1.12-1.33-2.45-2.39-3.99-3.18-1.54-.8-3.24-1.19-5.1-1.19-1.97,0-3.73,.41-5.28,1.24-1.55,.83-2.87,1.91-3.96,3.26-1.09,1.35-1.92,2.88-2.51,4.61-.59,1.73-.88,3.49-.88,5.28s.3,3.67,.9,5.41Zm4.61,11.65c-2.12-1.05-3.94-2.45-5.46-4.19-1.52-1.74-2.69-3.74-3.52-5.98-.83-2.24-1.24-4.54-1.24-6.89s.44-4.8,1.32-7.04c.88-2.24,2.1-4.23,3.65-5.95,1.55-1.72,3.39-3.1,5.52-4.12,2.12-1.02,4.43-1.53,6.91-1.53s4.94,.54,7.04,1.63c2.11,1.09,3.92,2.51,5.43,4.27,1.52,1.76,2.69,3.75,3.52,5.98,.83,2.23,1.24,4.49,1.24,6.81,0,2.45-.44,4.8-1.32,7.04-.88,2.24-2.08,4.22-3.62,5.93-1.53,1.71-3.36,3.07-5.49,4.09-2.12,1.02-4.43,1.53-6.91,1.53s-4.95-.53-7.07-1.58ZM4.66,90.07v28.06H0v-36.76H3.62l22.78,28.63v-28.58h4.66v36.71h-3.93L4.66,90.07Z"/>
        <path class="cls-1" d="M102.1,44.87l-11.75-15.48-22.79,20.53L77.33,3.9C77.81,1.64,79.87-.02,82.19,0h40.64c2.34,.02,4.39,1.77,4.78,4.08l8.39,46.46-21.58-21.15-12.33,15.47ZM123.89,4.72c-.09-.51-.59-.94-1.1-.94h-40.64c-.52,0-1.03,.41-1.13,.91l-7.36,34.65,17.21-15.5,11.3,14.89,11.91-14.95,16.24,15.91-6.42-34.96Z"/>
    </g>
    <g>
        <path class="cls-1" d="M9.4,142.74h-1.89l4.13-10.25h1.92l4.12,10.25h-1.89l-.94-2.39h-4.51l-.94,2.39Zm1.53-3.97h3.32l-1.66-4.25-1.66,4.25Z"/>
        <path class="cls-1" d="M32.37,134.87c-.32-.32-.71-.57-1.16-.75-.45-.17-.93-.26-1.43-.26s-.99,.09-1.41,.27c-.43,.18-.79,.43-1.1,.76-.31,.33-.55,.72-.72,1.18-.17,.45-.25,.96-.25,1.51s.08,1.07,.26,1.53c.17,.46,.41,.86,.72,1.2,.31,.34,.68,.6,1.11,.78,.43,.18,.9,.27,1.42,.27,.45,0,.86-.07,1.23-.21,.37-.14,.7-.34,.99-.6,.29-.26,.52-.58,.7-.95,.18-.37,.3-.78,.36-1.23h-3.24v-1.39h4.83l.05,.68c0,.78-.12,1.49-.36,2.14-.24,.64-.58,1.2-1.02,1.67-.44,.47-.97,.83-1.57,1.09-.61,.26-1.28,.38-2.01,.38s-1.46-.13-2.1-.4c-.64-.27-1.21-.64-1.69-1.12-.48-.48-.85-1.04-1.12-1.69-.27-.65-.4-1.35-.4-2.11s.13-1.46,.4-2.11,.64-1.22,1.11-1.7c.48-.48,1.04-.86,1.68-1.12,.65-.27,1.34-.4,2.1-.4s1.4,.12,2.05,.37c.65,.25,1.24,.6,1.75,1.06l-1.18,1.16Z"/>
        <path class="cls-1" d="M45.55,142.74h-2l-2.25-3.86h-1.94v3.86h-1.8v-10.25h3.78c.8,0,1.48,.13,2.04,.38,.56,.26,1,.62,1.31,1.1,.3,.48,.46,1.05,.46,1.72,0,.71-.18,1.31-.53,1.81-.35,.49-.85,.86-1.49,1.08l2.43,4.16Zm-6.19-5.33h1.85c.66,0,1.17-.15,1.54-.44,.37-.29,.55-.7,.55-1.23s-.18-.94-.55-1.23c-.37-.29-.88-.44-1.54-.44h-1.85v3.34Z"/>
        <path class="cls-1" d="M56.99,132.49v5.65c0,.99-.18,1.85-.53,2.57-.35,.72-.86,1.27-1.52,1.66-.66,.38-1.45,.58-2.37,.58s-1.7-.19-2.37-.58c-.66-.39-1.17-.94-1.52-1.66-.35-.72-.53-1.58-.53-2.57v-5.65h1.8v5.45c0,.74,.1,1.37,.3,1.88,.2,.51,.5,.9,.89,1.16s.86,.39,1.42,.39,1.04-.13,1.43-.39,.68-.64,.88-1.16c.2-.51,.3-1.14,.3-1.88v-5.45h1.8Z"/>
        <path class="cls-1" d="M60.21,142.74v-10.25h3.75c1.06,0,1.88,.23,2.48,.69,.59,.46,.89,1.1,.89,1.92,0,.53-.13,1-.38,1.41-.26,.4-.6,.7-1.03,.9,.6,.16,1.06,.46,1.4,.89,.34,.43,.51,.96,.51,1.58,0,.57-.14,1.08-.42,1.51-.28,.43-.68,.76-1.19,1-.51,.24-1.12,.36-1.81,.36h-4.19Zm1.8-5.89h1.85c.52,0,.93-.13,1.23-.38s.45-.6,.45-1.03-.15-.76-.46-1-.73-.37-1.26-.37h-1.8v2.78Zm0,4.3h1.95c.41,0,.77-.06,1.07-.19,.3-.13,.53-.3,.7-.53,.17-.23,.25-.49,.25-.8s-.08-.55-.23-.77c-.16-.22-.37-.38-.66-.5-.28-.12-.62-.17-1.01-.17h-2.06v2.95Z"/>
        <path class="cls-1" d="M70.62,142.74v-10.25h3.75c1.06,0,1.88,.23,2.48,.69,.59,.46,.89,1.1,.89,1.92,0,.53-.13,1-.38,1.41-.25,.4-.6,.7-1.03,.9,.6,.16,1.06,.46,1.4,.89,.34,.43,.51,.96,.51,1.58,0,.57-.14,1.08-.42,1.51-.28,.43-.68,.76-1.19,1-.51,.24-1.12,.36-1.81,.36h-4.19Zm1.8-5.89h1.85c.52,0,.93-.13,1.23-.38s.45-.6,.45-1.03-.15-.76-.46-1-.73-.37-1.26-.37h-1.8v2.78Zm0,4.3h1.95c.41,0,.77-.06,1.07-.19,.3-.13,.53-.3,.7-.53,.17-.23,.25-.49,.25-.8s-.08-.55-.23-.77c-.15-.22-.37-.38-.66-.5-.28-.12-.62-.17-1.01-.17h-2.06v2.95Z"/>
        <path class="cls-1" d="M89.02,142.74h-2l-2.25-3.86h-1.94v3.86h-1.8v-10.25h3.78c.8,0,1.48,.13,2.04,.38,.56,.26,1,.62,1.31,1.1,.3,.48,.46,1.05,.46,1.72,0,.71-.18,1.31-.53,1.81-.35,.49-.85,.86-1.49,1.08l2.43,4.16Zm-6.19-5.33h1.85c.66,0,1.17-.15,1.54-.44,.37-.29,.55-.7,.55-1.23s-.18-.94-.55-1.23c-.37-.29-.88-.44-1.54-.44h-1.85v3.34Z"/>
        <path class="cls-1" d="M99.69,142.74h-2l-2.25-3.86h-1.94v3.86h-1.8v-10.25h3.78c.8,0,1.48,.13,2.04,.38,.56,.26,1,.62,1.31,1.1,.3,.48,.46,1.05,.46,1.72,0,.71-.18,1.31-.53,1.81-.35,.49-.85,.86-1.49,1.08l2.43,4.16Zm-6.19-5.33h1.85c.66,0,1.17-.15,1.54-.44,.37-.29,.55-.7,.55-1.23s-.18-.94-.55-1.23c-.37-.29-.88-.44-1.54-.44h-1.85v3.34Z"/>
        <path class="cls-1" d="M103.65,138.68c-.56,0-1.07-.13-1.51-.38-.44-.25-.79-.6-1.04-1.04-.25-.44-.38-.94-.38-1.51s.13-1.06,.38-1.5c.25-.44,.6-.79,1.04-1.04,.44-.25,.95-.38,1.51-.38s1.07,.13,1.51,.38c.44,.25,.79,.6,1.04,1.04,.25,.44,.38,.94,.38,1.5s-.13,1.07-.38,1.51c-.25,.44-.6,.79-1.04,1.04-.44,.25-.94,.38-1.51,.38Zm0-.66c.43,0,.82-.1,1.16-.29,.34-.2,.6-.46,.8-.81,.19-.34,.29-.73,.29-1.16s-.1-.83-.29-1.17-.46-.61-.8-.81c-.34-.2-.72-.3-1.15-.3s-.81,.1-1.15,.3c-.34,.2-.61,.47-.81,.81-.2,.34-.3,.73-.3,1.17s.1,.82,.29,1.16,.46,.61,.8,.81c.34,.2,.73,.29,1.16,.29Zm1.28-.7h-.71l-.63-1.22h-.41v1.22h-.63v-3.13h1.15c.35,0,.62,.09,.83,.26,.2,.17,.3,.41,.3,.7,0,.21-.05,.39-.16,.54-.1,.15-.25,.26-.43,.33l.69,1.31Zm-1.75-1.77h.4c.2,0,.35-.04,.45-.1,.1-.07,.15-.17,.15-.32s-.05-.24-.15-.3c-.1-.07-.25-.1-.46-.1h-.4v.83Z"/>
        <path class="cls-1" d="M122.42,141.44c-.47,.47-1.04,.84-1.69,1.11-.65,.26-1.37,.4-2.14,.4s-1.48-.13-2.14-.4c-.66-.26-1.23-.64-1.71-1.12-.48-.48-.86-1.05-1.12-1.7s-.4-1.36-.4-2.12,.13-1.48,.4-2.13c.27-.65,.64-1.21,1.12-1.69,.48-.48,1.05-.85,1.71-1.12,.66-.26,1.37-.4,2.14-.4s1.48,.13,2.13,.4c.65,.26,1.21,.63,1.69,1.1l-1.26,1.28c-.31-.37-.69-.67-1.13-.88-.44-.21-.93-.31-1.46-.31s-.96,.09-1.39,.28c-.43,.19-.8,.45-1.11,.79-.31,.34-.56,.73-.74,1.19-.17,.46-.26,.95-.26,1.49s.09,1.03,.26,1.49c.18,.45,.42,.85,.74,1.19,.31,.34,.68,.6,1.11,.79,.43,.19,.89,.28,1.39,.28,.53,0,1.01-.1,1.46-.31,.44-.21,.82-.5,1.13-.88l1.27,1.27Z"/>
        <path class="cls-1" d="M130.3,142.95c-.77,0-1.49-.13-2.14-.4-.65-.26-1.22-.64-1.71-1.12-.48-.48-.86-1.05-1.12-1.7s-.4-1.36-.4-2.12,.13-1.48,.4-2.13c.27-.65,.64-1.21,1.12-1.69,.48-.48,1.05-.85,1.71-1.12,.65-.26,1.37-.4,2.15-.4s1.49,.13,2.14,.4c.65,.26,1.22,.64,1.7,1.12,.48,.48,.86,1.04,1.12,1.69,.26,.65,.4,1.36,.4,2.13s-.13,1.47-.4,2.12c-.26,.65-.64,1.21-1.12,1.7-.48,.48-1.05,.85-1.7,1.12-.65,.26-1.37,.4-2.15,.4Zm0-1.59c.51,0,.97-.09,1.4-.28,.43-.19,.8-.45,1.12-.79,.32-.34,.56-.74,.74-1.19,.17-.46,.26-.95,.26-1.48s-.09-1.03-.26-1.48c-.18-.46-.42-.85-.74-1.19-.32-.34-.69-.6-1.12-.79-.43-.19-.9-.28-1.4-.28s-.97,.09-1.4,.28c-.43,.19-.8,.45-1.12,.79-.32,.34-.56,.74-.74,1.19-.18,.46-.27,.95-.27,1.48s.09,1.03,.27,1.48c.18,.46,.42,.85,.74,1.19,.32,.34,.69,.6,1.12,.79,.43,.19,.9,.28,1.4,.28Z"/>
        <path class="cls-1" d="M149.24,142.74h-1.8v-7.69l-2.85,5.97h-1.46l-2.84-5.93v7.66h-1.8v-10.25h2.3l3.08,6.56,3.07-6.56h2.31v10.25Z"/>
        <path class="cls-1" d="M152.46,142.74v-10.25h3.39c.86,0,1.59,.14,2.21,.41,.62,.27,1.09,.66,1.42,1.17,.33,.51,.5,1.11,.5,1.81s-.17,1.3-.5,1.81c-.33,.51-.8,.9-1.42,1.18-.62,.28-1.35,.41-2.2,.41h-1.6v3.46h-1.8Zm1.8-5.04h1.42c.79,0,1.39-.16,1.82-.48s.64-.77,.64-1.34-.21-1.02-.64-1.33-1.03-.47-1.82-.47h-1.42v3.63Z"/>
        <path class="cls-1" d="M163.29,142.74h-1.89l4.13-10.25h1.92l4.12,10.25h-1.89l-.94-2.39h-4.51l-.94,2.39Zm1.53-3.97h3.32l-1.66-4.25-1.66,4.25Z"/>
        <path class="cls-1" d="M183.27,142.74h-2.04l-5.35-7.46v7.46h-1.8v-10.25h2.04l5.35,7.46v-7.46h1.8v10.25Z"/>
        <path class="cls-1" d="M191.43,142.74h-1.8v-4.38l-3.91-5.87h2.19l2.62,4.31,2.63-4.31h2.19l-3.92,5.89v4.37Z"/>
    </g>
  </svg>
`;

export const Logo = ({ className }) => (
  <div dangerouslySetInnerHTML={{__html: svg(className) }} />
);
