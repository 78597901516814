
import * as React from 'react';
import { submitPreorder } from '../../core/api';
import PreorderInput from './PreorderInput';

import './Preorders.scss';

const ANDROID_URL = 'https://play.google.com/store/apps/details?id=co.getnoble.nobleconsumer&hl=en_US';
const IOS_URL = 'https://itunes.apple.com/us/app/noble-drink-ordering-at-bars-in-boston-ma/id1211378457?mt=8';

export class PreorderReminderForm extends React.Component<any, any> {
  public onChangeName;
  public onChangeEmail;
  public onChangePhoneNumber;
  public onChangeDateOfEvent;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      saved: false,
      data: {
        name: '',
        email: '',
        phoneNumber: '',
        dateOfEvent: '',
      },
      error: '',
    };

    this.onChangeName = this.onChangeField.bind(this, 'name');
    this.onChangeEmail = this.onChangeField.bind(this, 'email');
    this.onChangePhoneNumber = this.onChangeField.bind(this, 'phoneNumber');
    this.onChangeDateOfEvent = this.onChangeField.bind(this, 'dateOfEvent');

    this.submitForm = this.submitForm.bind(this);
  }

  onChangeField(field, value) {
    this.setState({
      data: {
        ...this.state.data,
        [ field ]: value,
      },
    });
  }

  async submitForm(e) {
    e.preventDefault();
    this.setState({ loading: true });

    try {
      await submitPreorder({
        ...this.state.data,
        venueId: this.props.venue.id,
        dateOfEvent: (new Date(Date.parse(this.state.data.dateOfEvent))).toISOString(),
      });
      this.setState({ loading: false, saved: true });
    } catch (error) {
      this.setState({ loading: false, error: error.message });
    }
  }

  renderVenueLogo() {
    if (this.props.venue && this.props.venue.config && this.props.venue.config.preorderLogoUrl) {
      return <img className='preorder-venue-logo' src={this.props.venue.config.preorderLogoUrl} />;
    }

    return null;
  }

  renderContent() {
    if (this.state.loading) {
      return <p>Loading...</p>;
    } else if (this.state.saved) {
      return (
        <div>
          <p className='preorder-intro-text'>Your pre-order reminder is set!</p>
          <p>You are all set. We have your reminder lined up and ready to go.</p>
          <p className='text-justified'>
            Get the Noble app from the App Store or Google Play and check out what is on the menu!
          </p>
          <p className='preorder-store-icons'>
            <a href={IOS_URL} target='_blank'>
              <img src='/static/img/kit/ios.png' className='preorder-store-icon preorder-store-icon-ios' />
            </a>
            <a href={ANDROID_URL} target='_blank'>
              <img src='/static/img/kit/android.png' className='preorder-store-icon' />
            </a>
          </p>
        </div>
      );
    } else {
      const { data } = this.state;
      const phoneInputProps = {
        id: 'phoneNumber',
        value: data.phoneNumber,
        onChange: this.onChangePhoneNumber,
        shrink: 90,
        label: 'Phone Number',
      };

      const dateOfEventInputProps = {
        date: true,
        id: 'date',
        value: data.dateOfEvent,
        onChange: this.onChangeDateOfEvent,
        shrink: 90,
        label: 'Date Of Event',
      };

      return (
        <div>
          <p className='preorder-intro-text'>Get ready to skip the food &amp; drink lines for your upcoming event!</p>
          <p>
            Noble makes ordering food &amp; drinks from your favorite entertainment venues a hassle-free experience.
            Place your order from your phone and pick it up or have it delivered to your seat when it is ready!
          </p>
          <p>
            Get the Noble app from the
            <a target='_blank' href={IOS_URL}> App Store</a> or
            <a target='_blank' href={ANDROID_URL}> Google Play </a>
            to put in your pre-order now and have your drinks ready for you to pick-up the day of
            your event at your selected time.
          </p>
          <p className='set-reminder-btn'>
            <a href='/download' className='btn btn--purple-gradient preorder-mobile-download'>
              Pre-order in the Noble app
            </a>
            <a href='/#download' className='btn btn--purple-gradient preorder-desktop-download'>
              Pre-order in the Noble app
            </a>
          </p>
          <p>
            Need a simple reminder the day of your event to get your Noble pre-order in? Put in your information below
            and we’ll send you a friendly reminder with a link to the app.
          </p>
          <p className='preorder-error'>{this.state.error}</p>
          <form onSubmit={this.submitForm} className='preorder-form'>
            <div className='preorder-row'>
              <div className='preorder-column'>
                <PreorderInput id='name' value={data.name} onChange={this.onChangeName} shrink={90} label='Name' />
              </div>
              <div className='preorder-column'>
                <PreorderInput id='email' value={data.email} onChange={this.onChangeEmail} shrink={90} label='Email' />
              </div>
            </div>
            <div className='preorder-row'>
              <div className='preorder-column'>
                <PreorderInput {...phoneInputProps} />
              </div>
              <div className='preorder-column'>
                <PreorderInput {...dateOfEventInputProps}  />
              </div>
            </div>
            <button type='submit' className='btn btn--purple-gradient'>Set Reminder</button>
          </form>
        </div>
      );
    }
  }

  render() {
    return (
      <div className='preorder-container'>
        <div className='preorder-logos'>
          {this.renderVenueLogo()}
          <img className='preorder-noble-logo' src='/static/img/noble-logo.jpg' />
        </div>
        {this.renderContent()}
        <small className='preorder-copy'>Copyright © 2018 GetNoble Inc</small>
      </div>
    );
  }
}
