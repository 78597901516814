import { RichText } from 'prismic-reactjs';
import * as React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { venueNav } from '../../constants/NavLinks';
import LinkResolver from '../../core/linkResolver';
import { Navbar, Footer } from '../../components';
import { withNavControl } from '../../hocs/withNavControl';
import './CaseStudies.scss';

const getPreviewImage = caseStudy => caseStudy.list_preview_image && caseStudy.list_preview_image.url;

const CaseStudy = ({ caseStudy }) => {
  return (
    <div className='case-stud-wrapper'>
      <article className='case-study'>
        <div className='case-study__img' style={{ backgroundImage: `url(${getPreviewImage(caseStudy)})` }} />
        <div className='case-study__content'>
          <h3 className='case-study__header'>
            <a href={`/venues/case-study/${caseStudy._meta.uid}`}>{RichText.asText(caseStudy.title)}</a>
          </h3>
          <div className='case-study__desc'>{RichText.render(caseStudy.preview_text, LinkResolver)}</div>
        </div>
      </article>
    </div>
  );
};

const CASE_STUDIES_QUERY = gql`
query {
  allCase_studys {
    edges {
      node {
        _meta {
          uid
        }
        title
        preview_text
        location
        noble_setup
        capacity
        list_preview_image
        _linkType
      }
    }
  }
}
`;

export const CaseStudies = withNavControl(({ navPassedHero, heroRef }) => {
  const { data } = useQuery(CASE_STUDIES_QUERY);
  const caseStudies = data ? data.allCase_studys.edges.map(node => node.node) : null;

  return (
    <div>
      <Navbar links={venueNav.links} cta={venueNav.cta} passedHero={navPassedHero} />
      <div ref={heroRef} className='hero hero-sm case-studies-hero'>
        <div className='hero__content'>
          <h1 className='hero__title'>Case Studies</h1>
          <h3 className='hero__subtitle'>
            See how Noble is changing mobile ordering in various industries
          </h3>
        </div>
      </div>
      <section className='container case-studies'>
        {caseStudies && caseStudies.map( (caseStudy, index) => <CaseStudy key={index} caseStudy={caseStudy} /> )}
      </section>
      <section className='case-studies-join'>
        <img src='/static/img/case-studies/phones.png' className='hidden--md' />
        <div className='case-studies-join__content'>
          <h3 className='case-studies-join__header'>Join the Noble life</h3>
          <p>
            Ready to get started? Order your Noble kit and begin to see how mobile ordering can enhance the guest
            experience at your venue.
          </p>
          <a href='/venues' className='btn btn--small-padding btn--white case-studies-join__button'>Get the Noble Kit</a>
        </div>
        <img src='/static/img/venues/venue-list-iphone@2x.png' className='show--sm case-studies-join__phone' />
      </section>
      <Footer />
    </div>
  );
});
