
/* tslint:disable:max-line-length */

import * as React from 'react';
import { LegalLayout } from '../../components';

export const PrivacyPolicy = () => (
  <LegalLayout title='Privacy Policy'>
    <div>
      <h2>GetNoble Inc. Privacy Policy</h2>
      <p>Effective Date: January 1, 2017</p>
      <p>This privacy policy (this “Policy”) explains how personal information is collected, used, and disclosed by GetNoble Inc. (“Noble” or “we”). This Policy applies to consumer users (individually referred to as “you”) of Noble’s websites, the Noble’s mobile application (the “App”), and the services accessible through the Website or App (collectively, our “Sites”)</p>
      <p>Other third parties, including without limitation, nightclubs and lounges marketing and providing services on the Sites, and third party payment services integrated into the Sites, may also collect, use, and share information about you. This Policy does not cover such third parties or their services. For information about third-party privacy practices, please consult with them directly</p>
      <p>We reserve the right to change the provisions of this Policy at any time. We will alert you that changes have been made by indicating on this Policy the date it was last updated. We encourage you to review this Policy from time-to-time to make sure that you understand how any personal information you provide to us or collected about you will be used.</p>
      <h2>INFORMATION WE COLLECT</h2>
      <p>We collect information about you in various ways when you use our Sites. We use this information to, among other things, provide the functionality and improve the quality of our Sites and personalize your experience.</p>
      <p><strong>Active Collection.</strong> &nbsp;We collect information from you when you use the Sites. For example, when you create an account on our Sites, we may ask you to provide your name, email address, postal address, phone number (including your mobile phone number), and other information. You can choose not to provide certain personal information but, if you choose to do so, you might not be able to take advantage of the Service.</p>
      <p>We collect financial information, such as your payment method (valid credit card number, type, expiration date or other financial information) only through a third party payment process integrated into our App. That information is collected and stored by our third party payment processing company (the “Payment Processor”), and use and storage of that information is governed by the Payment Processor’s applicable terms of service and privacy policy.</p>
      <p><strong>Passive Collection.</strong> &nbsp;When you use the Service, some information is also automatically collected, such as your Internet Protocol (IP) address, your operating system, the browser type, the address of a referring website, and your activity on the Service. We may also automatically collect certain logistical information in server logs, including information about how you use various features of the Service and information about the number, frequency and length of each session.</p>
      <p>We may also automatically collect certain information through the use of “cookies.” Cookies are small data files stored on your hard drive at the request of a website. Among other things, cookies help us to improve the Service and your experience. If you wish to block, erase, or be warned of cookies, please refer to your browser manufacturer to learn about these functions. However, if you choose to remove or reject cookies, this could affect certain features or services made available on the Sites.</p>
      <p>We may use Web beacons or similar technology on the Sites. Web beacons are small, invisible graphic images that may be used on the Sites to collect certain information and monitor user activity, such as to count visits and understand usage effectiveness.</p>
      <p>We may collect device-specific information (such as your hardware model, operating system version, unique device identifiers, and mobile network information including phone number). We may associate your device identifiers or phone number with your Noble account.</p>
      <p>We currently do not employ technology that recognizes “do-not-track” signals. We may engage third parties, such as marketing or analytics partners, who may collect information about your online activities over time and across different websites when you use our Sites. </p>
      <p>Personal information will be stored on secured servers, and we may store and process personal information in the United States and other countries.</p>
      <h2>HOW WE USE INFORMATION WE COLLECT</h2>
      <p>We use personal information collected through our Sites for the purposes described in this Policy or disclosed to you on our Sites or otherwise in connection with our services. For example, we may use your information to:</p>
      <ul>
      <li>process and complete transactions in connection with the services (e.g. to make premium services of nightclubs and lounges accessible to you);</li>
      <li>to request feedback and to otherwise contact you about your use of the service;</li>
      <li>to respond to your emails, questions, comments, requests and complaints, and to provide customer service;</li>
      <li>to monitor and analyze Site usage and trends;</li>
      <li>to personalize and improve the Sites, and our users’ experiences with the Sites (such as providing content or features that match interests), and to increase the Sites’ functionality and user friendliness;</li>
      <li>to provide you with news and information about our or third-party events, offers, promotions and services we think will be of interest to you;</li>
      <li>to send you confirmations, updates, security alerts and support and administrative messages, and otherwise facilitate your use of, and our administration and operation of, the Sites;</li>
      <li>to notify you about important changes to the service; and</li>
      <li>for any other purpose for which the information was collected.</li>
      </ul>
      <h2>HOW WE SHARE INFORMATION</h2>
      <p>Information Shared With Nightclubs and Lounges.  When you purchase access to premium services of nightclubs and lounges (the “Venues”) through our App, we provide your personal information to such Venues in order to facilitate your access to the premium services. Noble does not control the privacy practices of Venues. Please contact the Venue directly if you want to learn about its privacy practices.</p>
      <p>Sharing with Others.  We do not share your personal information with third parties other than as described above and as follows:</p>
      <p>We may share personal information with affiliates, third-party vendors, consultants, and other service providers who work for us. Such third-party vendors may include vendors who provide fraud detection services to us and other third parties.</p>
      <p>We may share aggregate statistical data for the improvement of services offered by our Sites.</p>
      <p>We share personal information with third parties who provide services to us, such as data collection, reporting, ad response measurement, and site analytics, as well as assistance with delivery of relevant marketing messages and advertisements. These third parties may view, edit, or set their own cookies. We and our third-party service providers, advertisers, and/or partners may also place web beacons for these third parties. The use of these technologies by these third parties is subject to their own privacy policies and is not covered by this Policy.</p>
      <p>We may disclose your personal information (i) to comply with laws and to respond to lawful requests and legal process, (ii) to protect the rights and property of Noble, our agents and customers, and others, including to enforce our agreements, policies, and terms of use, and (iii) in an emergency to protect the personal safety of you, Noble customers, or any other person.</p>
      <p>We may disclose or transfer your personal information to a third party if we sell, transfer, divest, or disclose all or a portion of our business or assets to another company in connection with or during negotiation of any merger, financing, or acquisition transaction, or any bankruptcy or dissolution proceeding.</p>
      <p>We may also share with third parties aggregated or de-identified information that does not identify you.</p>
      <h2>LINKS TO OTHER WEBSITES</h2>
      <p>The Sites may contain links to other websites. Any personal information you provide on the linked pages is provided directly to that third- party and is subject to that third-party’s privacy policy.</p>
      <p>This Policy does not apply to such linked websites, and we are not responsible for the content or privacy and security practices and policies of these websites or any other websites that are linked to/from the Sites. We encourage you to learn about their privacy and security practices and policies before providing them with personally identifiable information.</p>
      <h2>SECURITY OF YOUR PERSONAL INFORMATION</h2>
      <p>We take reasonable measures to protect your personal information in an effort to prevent loss, misuse, and unauthorized access, disclosure, alteration, and destruction. Please be aware, however, that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can guarantee against any interception or any other type of misuse. To assist with the protection of personal information, you must keep your password confidential and not disclose it to any other person. You are responsible for all uses of the Sites and our services by any person using your password. Please advise us immediately if you believe your password has been misused.</p>
      <h2>YOUR INFORMATION CHOICES AND CHANGES</h2>
      <p>In the event we decide to use your personal information for any purpose other than as stated in this Policy, we will offer you an effective way to opt out of the use of your personal information for those other purposes.&nbsp;You may opt out of receiving promotional emails from Noble by following the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as emails about your Noble account or our ongoing business relations.</p>
      <h2>CONTACTING NOBLE</h2>
      <p>For questions about this Policy or accessing, changing, or deleting your personal information you may contact Noble at 415-662-8355 or at the follow <a href='mailto:hello@getnoble.co'>hello@getnoble.co.</a></p>
    </div>
  </LegalLayout>
);
