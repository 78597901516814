import { PrismicLink } from "apollo-link-prismic";
import ApolloClient from "apollo-client";

export const createClient = ({ ssrMode, cache }) => {
  return new ApolloClient({
    ssrMode,
    link: PrismicLink({
      uri: "https://getnoble.prismic.io/graphql",
    }),
    cache,
  });  
}
