import * as React from 'react';
import { venueNav } from '../../constants/NavLinks';
import { Footer, Navbar } from '../../components';
import { withNavControl } from '../../hocs';
import './About.scss';
import '../../styles/index.scss';
import Crown from './Crown';

const tenets = [
  {
    icon: '/static/img/about/simple-icon.png',
    title: 'Keep it simple',
    description: `Design products that are easy to use for all users. We’ve kept a strict focus on easy order, easy
                  pay and customers have given us their feedback in over 250+ 5 star reviews.`,
  },
  {
    icon: '/static/img/about/simple-icon.png',
    title: 'Fulfill a need',
    description: `We learn from a our industry verticals and understand the needs of all stakeholders and customers.
                  This has helped us define gamechanging features that customers and venues need and love.`,
  },
  {
    icon: '/static/img/about/simple-icon.png',
    title: 'Enjoy more',
    description: `Noble allows customers to order from their seat and get a notification when their order is ready for
                  pick-up. Always allow customers to spend more time enjoying the event and less time waiting at the
                  bar.`,
  },
];

const Tenet = ({ tenet }) => (
  <div className='about-tenets-tenant'>
    <img className='about-tenets-tenant__icon' src={tenet.icon} alt={tenet.title} />
    <h5 className='about-tenets-tenant__header'>{tenet.title}</h5>
    <p className='about-tenets-tenant__desc'>{tenet.description}</p>
  </div>
);

export const About = withNavControl(({ navPassedHero, heroRef }) => (
  <div>
    <Navbar links={venueNav.links} cta={venueNav.cta} passedHero={navPassedHero} />
    <div className='about'>
      <div ref={heroRef} className='about-hero hero hero-sm'>
        <div className='hero__content'>
          <h1 className='hero__title'>Wait less. Enjoy more.</h1>
          <h3 className='hero__subtitle'>
            We believe that meaningful experiences are created when the focus is on the event and not on the process.
          </h3>
          <a className='btn btn--outline' href='/download'>Get the Noble app</a>
        </div>
      </div>
      <section className='container about-mission'>
        <h2 className='about-mission__title'>Our mission</h2>
        <h4 className='about-mission__subtitle'>
          Redefine mobile ordering as a memorable part of the event experience.
        </h4>
        <p className='about-mission__copy'>
          Started in 2016 as a solution to long lines at event venues, Noble has evolved to focus on skipping lines
          but at the place where it really matters, the concession. Today, we allow customers to pre-order their
          concession items and skip the long lines when they come to pick them up. We aim to shake up the world of
          guest experiences at entertainment venues across the world.
        </p>
      </section>
      <section className='about-tenets container'>
        <h2 className='about-tenets__header'>Our 3 tenets</h2>
        <p className='about-tenets__desc'>
          When building products, we believe in three key tenets to enhance the experience.
        </p>
        <div className='about-tenets-row'>
          {tenets.map( (tenet, index) => <Tenet key={index} tenet={tenet} /> )}
        </div>
      </section>
      <section className='container about-culture'>
        <h2 className='about-culture__header'>Our culture</h2>
        <p className='about-culture__desc'>
          We’ve created a fantastic product because of the team and our belief in taking part in the events Noble is
          part of. We understand our customers’ needs while having fun doing it.
        </p>
        <div className='about-culture__imgs'>
          <img src='/static/img/about/culture1.jpg' />
          <img src='/static/img/about/culture2.jpg' />
          <img src='/static/img/about/culture3.jpg' />
          <img src='/static/img/about/culture4.jpg' />
        </div>
        <Crown className='about-culture-crown' />
        <p className='about-culture__secondary-header'>Get in touch</p>
        <p className='about-culture__company-header'>Noble</p>
        <p className='about-culture__address'>695 Atlantic Avenue</p>
        <p className='about-culture__address'>9th Floor</p>
        <p className='about-culture__address'>Boston, MA 02111</p>
        <p className='about-culture__contact'>
          e: <a href='mailto:hello@getnoble.co'>hello@getnoble.co</a> |
          p: (617) 334-5161
        </p>
      </section>
      <Footer />
    </div>
  </div>
));
