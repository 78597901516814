
import * as React from 'react';
// import "./index.scss";

const ALT = 'Noble: Easy Order, Easy Pay';

const IOS_URL = 'https://itunes.apple.com/us/app/noble-easy-order-easy-pay/id1211378457?mt=8';
const ANDROID_URL = 'https://play.google.com/store/apps/details?id=co.getnoble.nobleconsumer&amp;hl=en_US';

export const Availability = () => (
  <div>
    <div className='large-row two-col-lockup two-col-lockup__vertical--align availability'>
      <div className='two-col-lockup__image two-col-lockup__image--centered two-col-lockup__vertical--align'>
        <img alt={ALT} className='img hidden--sm' src='/static/img/easy-order-easy-pay-iphones.png' />
      </div>
      <div className='venues__copy' id='about'>
        <h1 className='venues__title'>
          Available on<br />iOS &amp; Android
        </h1>
        <p>
          Whether you’re at a theater, stadium, concert, or another exciting event or venue, Noble is there to help you
          make the most of your experience. Let us take care of the ordering so you never miss the fun.
        </p>
        <p>
          <a href={IOS_URL} target='_blank'>
            <img src='/static/img/kit/ios.png' className='availability__download-icon' />
          </a>
          <a href={ANDROID_URL} target='_blank'>
            <img src='/static/img/kit/android.png' className='availability__download-icon' />
          </a>
        </p>
        <img src='/static/img/venues/venue-list-iphone@2x.png' className='show--sm availability__phone' />
      </div>
    </div>
  </div>
);
