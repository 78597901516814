// @flow

import classNames from 'classnames';
import dateFns from 'date-fns';
import * as React from 'react';
import Calendar from './Calendar';

interface Props {
  className?: string;
  fontSize?: string;
  id: string;
  label: string;
  onBlur?: (event) => void;
  onChange: (text) => void;
  onFocus?: (event) => void;
  shrink: number;
  type?: string;
  value: string;
  date?: boolean;
}

export default class FloatingLabelInput extends React.Component<Props, { active: boolean, calendarOpen: boolean }> {
  constructor(props) {
    super(props);

    if (!props.id) {
      throw new Error('expectd id but none present');
    }

    if (props.shrink && (props.shrink < 1 || props.shrink > 99)) {
      throw new Error('shrink prop must be between 1 and 99');
    }

    this.onChange = this.onChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);

    this.state = {
      active: props.value && props.value.length > 0,
      calendarOpen: false,
    };
  }

  componentDidUpdate() {
    if (this.props.value.length > 0 && !this.state.active) {
      this.setState({ active: true });
    }
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onChangeDate(date) {
    this.setState({ calendarOpen: false }, () => {
      this.props.onChange(dateFns.format(date, 'M/D/YYYY'));
    });
  }

  toggleCalendar() {
    this.setState({ calendarOpen: !this.state.calendarOpen });
  }

  renderDateIcon() {
    if (this.props.date) {
      return (
        <span className='preorder-cal-icon' onClick={this.toggleCalendar}>
          <img src='/static/img/preorder/calendar-icon.png' />
        </span>
      );
    }

    return null;
  }

  onBlur = event => {
    this.setState({ active: event.target.value.length !== 0 });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  onFocus = event => {
    this.setState({ active: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  renderInput() {
    const { id, label, type, value } = this.props;

    return (
      <input
        required={true}
        id={id}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        placeholder={label}
        type={type || 'text'}
        value={value}
      />
    );
  }

  renderLabel() {
    const { id, shrink, label } = this.props;
    const { active } = this.state;
    const className = classNames('react-floating-label-input-label', {
      'react-floating-label-input-label-active': active,
    });

    const style = { transform: active ? `translate3d(0, -${shrink + 20}%, 0) scale(1, 0.${shrink})` : 'none' };

    return (
      <label htmlFor={id} className={className} style={style}>
        {label}
      </label>
    );
  }

  render() {
    const { className, fontSize, value } = this.props;
    const { active } = this.state;

    return (
      <div className={classNames('react-floating-label-input', { active, [className!]: !!className })}>
        <div className='react-floating-label-input-container' style={{ fontSize: fontSize ? fontSize : 'inherit' }}>
          {this.renderLabel()}
          {this.renderInput()}
          {this.renderDateIcon()}
          {this.state.calendarOpen && <Calendar onSelect={this.onChangeDate} value={Date.parse(value)} />}
        </div>
      </div>
    );
  }
}
