
import * as React from 'react';

export const Info = () => (
  <div>
    <div className='two-col-lockup two-col-lockup__vertical--align'>
      <div className='two-col-lockup__image two-col-lockup__image--centered two-col-lockup__vertical--align'>
        <img alt='Noble: Easy Order, Easy Pay' className='img' src='/static/img/kit/noble-tablet.jpg' />
      </div>
      <div className='kit__copy' id='about'>
        <h1 className='kit__title'>
          A system built around simplicity
        </h1>
        <p>
          Whether you’re at a theater, stadium, concert, or another exciting event or venue, Noble is there to help you
          make the most of your experience. Let us take care of the ordering so you never miss the fun.
        </p>
      </div>
    </div>
    <div className='two-col-lockup two-col-lockup__vertical--align'>
      <div className='two-col-lockup__copy' id='about'>
        <h1 className='kit__title'>
          Easy wireless receipt printing
        </h1>
        <p>
          Whether you’re at a theater, stadium, concert, or another exciting event or venue, Noble is there to help you
          make the most of your experience. Let us take care of the ordering so you never miss the fun.
        </p>
      </div>

      <div className='two-col-lockup__image two-col-lockup__image--centered two-col-lockup__vertical--align'>
        <img alt='Noble: Easy Order, Easy Pay' className='img' src='/static/img/kit/system-concession.jpg' />
      </div>
    </div>
  </div>
);
