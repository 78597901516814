
import * as React from 'react';
import { ResponsiveImage } from '../../../components';

const benefits = require('./data.json');

const Benefit = ({ benefit }) => (
  <div className='benefit'>
    <div className='benefit__icon-wrapper'>
      <ResponsiveImage
        className='benefit__icon'
        basePath='/img/venues'
        fileName={benefit.icon}
        extension='png'
        alt={benefit.title}
      />
    </div>
    <h4 className='benefit__title'>{benefit.title}</h4>
    <p className='benefit__desc'>{benefit.description}</p>
  </div>
);

export default () => (
  <section className='benefits'>
    <h2 className='header header--lg header--purple'>Benefits of Noble</h2>
    <p className='subheader'>Bringing Noble to your venue(s) has benefits for everyone in your organization.
    Whether you’re the GM or on the marketing team, Noble is here to give you the advantage.</p>
    <div className='benefits__list'>
      {benefits.map( (benefit, index) => <Benefit benefit={benefit} key={index} /> )}
    </div>
  </section>
);
