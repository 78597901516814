
import * as React from 'react';
import { venueNav } from '../../constants/NavLinks';
import { Footer, Navbar, PurposefulVenues, GetInTouch } from '../../components';
import { Availability } from './Availability';
import { CarouselContainer } from './Carousel';
import { Info } from './Info';
import './Kit.scss';
import { OrderForm } from './OrderForm';

export const Kit = () => (
  <div className='kit-container'>
    <Navbar links={venueNav.links} cta={venueNav.cta} passedHero={true} />
    <CarouselContainer />
    <section className='container'>
      <div className='kit'>
        <h1 className='header header--lg header--purple'>Noble Mobile Ordering Kit</h1>
        <OrderForm />
        <p>
          Noble allows your guests to never miss a moment of the event and for venues to capture missed sales
          opportunities by allowing customers to order directly from their phone.  The Noble kit includes the following:
        </p>
        <ul>
          <li>Wi-fi enabled iPad with Noble software</li>
          <li>Noble iPad stand</li>
          <li>Wireless bluetooth receipt printer</li>
        </ul>
      </div>
    </section>
    <Info />
    <PurposefulVenues />
    <Availability />
    <GetInTouch />
    <Footer />
    <script src='https://www.paypalobjects.com/api/checkout.js' data-version-4={true} />
  </div>
);
