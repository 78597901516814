
import classnames from 'classnames';
import * as React from 'react';
import ReactDropdown from 'react-dropdown';
import './Dropdown.scss';

export const Dropdown = ({ value, options, onChange, placeholder, hasError }) => (
  <ReactDropdown 
    controlClassName={classnames('noble-dropdown', { 'noble-dropdown-error': hasError })}
    arrowClassName='noble-dropdown-arrow'
    options={options}
    onChange={onChange}
    placeholder={placeholder}
    value={value}
  />
);
