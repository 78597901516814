
import { CarouselProvider, WithStore } from 'pure-react-carousel';
import * as React from 'react';
import { PurpleDotCarouselNav } from '../PurpleDotCarouselNav';
import { PurposefulVenueCarousel } from './PurposefulVenuesCarousel';

const venueTypes = require('./data.json');
const carouselProps = {
  isPlaying: false,
  dragEnabled: true,
  touchEnabled: true,
  naturalSlideWidth: 0,
  naturalSlideHeight: 5,
  totalSlides: venueTypes.length,
  currentSlide: Math.round(venueTypes.length / 2),
};

const Decorated = WithStore(PurposefulVenueCarousel, state => ({
  currentSlide: state.currentSlide,
}));

export class PurposefulVenues extends React.Component<any, any> {
  render() {
    return (
      <CarouselProvider {...carouselProps}>
        <Decorated currentSlide={carouselProps.currentSlide}/>
        <PurpleDotCarouselNav data={venueTypes} className='purposeful__dots' />
      </CarouselProvider>
    );
  }
}
