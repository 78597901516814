
import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import * as React from 'react';
import './QuoteCarousel.scss';

const carouselProps = {
  className: 'reviews__carousel',
  isPlaying: true,
  dragEnabled: false,
  touchEnabled: false,
  naturalSlideWidth: 1,
  naturalSlideHeight: 1,
};

const QuoteSlide = ({ quote, index }) => (
  <Slide index={index}>
    <div role='presentation' className='reviews__review'>
      {quote.starImage && <img className='reviews__review-stars' src={quote.starImage} />}
      <p className='reviews__review-copy'>{quote.quote}</p>
      {quote.by && <p className='reviews__review-by'>- {quote.by}</p>}
    </div>
    <img className='reviews__review-bg' src={quote.image} />
  </Slide>
);

export const QuoteCarousel = ({ quotes }) => (
  <div className='reviews slider-container'>
    <div className='reviews__open-quote'>
      <img role='presentation' src='/static/img/reviews/quote.svg' />
    </div>

    <CarouselProvider {...carouselProps} totalSlides={quotes.length}>
      <div className='reviews__content'>
        <div className='reviews__dots'>
          {quotes.map( (_, index) => <Dot key={index} className='reviews__dot' slide={index}>&nbsp;</Dot> )}
        </div>
        <Slider>
          {quotes.map( (quote, index) => <QuoteSlide key={index} quote={quote} index={index} /> )}
        </Slider>
      </div>
    </CarouselProvider>

    <div className='reviews__closed-quote'>
      <img role='presentation' src='/static/img/reviews/unquote.svg' />
    </div>
  </div>
);
