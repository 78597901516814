
const linkResolver = (doc, _ctx) => {
  // URL for a category type
  if (doc.type == 'case_study') {
    return '/venues/case-study/' + doc.uid;
  }
  
  // Backup for all other types
  return '/';
};

export default linkResolver;