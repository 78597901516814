
import * as React from 'react';

const formProps = {
  action: 'https://GetNoble.us15.list-manage.com/subscribe/post?u=65f18ca21821a99f200c7a4c0&amp;id=6da0c6127e',
  method: 'post',
  id: 'mc-embedded-subscribe-form',
  name: 'mc-embedded-subscribe-form',
  className: 'validate',
  target: '_blank',
  noValidate: true,
};

export default () => (
  <div className='email'>
    <h1 className='footer__heading'>Get exclusive Noble offers by email</h1>
    <div id='mc_embed_signup'>
    <form {...formProps}>
    <div id='mc_embed_signup_scroll'>
    <div className='mc-field-group'>
    <div className='email__form'>
      <input type='email' name='EMAIL' id='mce-EMAIL' placeholder='Enter your email' />
      <button id='mc-embedded-subscribe' className='btn btn-attached--right btn--blue' type='submit'>Submit</button>
    </div>
    </div>
      <div id='mce-responses' className='clear'>
        <div className='response' id='mce-error-response' style={{ display: 'none' }} />
        <div className='response' id='mce-success-response' style={{ display: 'none' }} />
      </div>
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
        <input type='text' name='b_65f18ca21821a99f200c7a4c0_6da0c6127e' tabIndex={-1} value='' /></div>
      </div>
    </form>
    </div>
  </div>
);
