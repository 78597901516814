import * as React from 'react';
import { consumerNav } from '../../constants/NavLinks';
import { Footer, Navbar, QuoteCarousel } from '../../components';
import { withNavControl } from '../../hocs';
import EasyOrder from './EasyOrder';
import Hero from './Hero';
import Join from './Join';
import Rewards from './Rewards';
import '../../styles/index.scss';
import './Home.scss';

const quotes = require('./quotesData.json');

export const Home = withNavControl(({ navPassedHero, heroRef }) => (
  <div>
    <Navbar links={consumerNav.links} cta={consumerNav.cta} passedHero={navPassedHero} />
    <div>
      <Hero ref={heroRef} />
      <EasyOrder />
      <Rewards />
      <QuoteCarousel quotes={quotes} />
      <Join />
      <div className='owner-cta'>
        <h1 className='owner-cta__title'>Are you a venue owner?</h1>
        <a className='btn btn--outline' href='/venues'>Learn More</a>
      </div>
      <Footer />
    </div>
  </div>
));
