import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { RichText } from 'prismic-reactjs';
import { format } from 'date-fns';
import { consumerNav } from '../../constants/NavLinks';
import { Footer, Navbar } from '../../components';
import './PressArticle.scss';

const ArticleBody = ({ pressArticle }) => {
  return (
    <article className='press-article-full'>
    <h1 className='press-article-full__title'>{RichText.asText(pressArticle.title)}</h1>
    <p className='press-article-full__subtitle'>
      {RichText.asText(pressArticle.source)} • {format(new Date(pressArticle.date), 'MMMM D, YYYY')}
    </p>
    {RichText.render(pressArticle.content)}
  </article>
  );
}

const ARTICLE_QUERY = gql`
query PressArticleQuery($id: String) {
  allPress_articles(uid: $id) {
    edges {
      node {
        _meta {
          uid
        }
        title
        source
        date
        preview
        content
        _linkType
      }
    }
  }
}
`;

export const PressArticle = ({ match, client }: any) => {
  const { id } = match.params;
  const { data } = useQuery(ARTICLE_QUERY, {
    variables: {
      id,
    }
  });
  const pressArticle = data ? data.allPress_articles.edges.map(node => node.node)[0] : null;

  return(
    <div>
      <Navbar links={consumerNav.links} cta={consumerNav.cta} passedHero={true} />
      {pressArticle && <ArticleBody pressArticle={pressArticle} />}
      <Footer />
    </div>
  );
};
