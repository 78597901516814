import { App } from './App';
import { InMemoryCache } from 'apollo-cache-inmemory';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import { ApolloProvider } from 'react-apollo';
import { createClient } from './Apollo';
import React from 'react';
import { hydrate } from 'react-dom';

console.log((window as any).__APOLLO_STATE__);
const cache = new InMemoryCache().restore((window as any).__APOLLO_STATE__);
const client = createClient({
  ssrMode: false,
  cache,
});

hydrate(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
