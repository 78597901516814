
import * as React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { venueNav } from '../../constants/NavLinks';
import { withNavControl } from '../../hocs/withNavControl';
import { QuoteCarousel, PurposefulVenues, Navbar, GetInTouch, Footer } from '../../components';
import Benefits from './Benefits';
import CaseStudies from './CaseStudies';
import EasyOrder from './EasyOrder';
import Join from './Join';
import VenuesSection from './Venues';
import './Venues.scss';

const quotesData = require('./quotesData.json');

interface VenuesProps {
  navPassedHero: boolean;
  heroRef: any;
}

const VIDEO_URL = 'https://s3.amazonaws.com/noble-web-assets/demo.mp4';

const CASE_STUDIES_QUERY = gql`
query {
  allCase_studys {
    edges {
      node {
        _meta {
          uid
        }
        title
        preview_text
        location
        noble_setup
        capacity
        list_preview_image
        _linkType
      }
    }
  }
}
`;

export const Venues = withNavControl(({ navPassedHero, heroRef }: VenuesProps) => {
  const [lightboxOpen, setLightboxOpen] = React.useState<boolean>(false);
  const toggleLightbox = React.useCallback(() => {
    setLightboxOpen(!lightboxOpen);
  }, [setLightboxOpen, lightboxOpen]);
  let overlay;
  if (lightboxOpen) {
    overlay = (
      <div className='demo-lightbox' onClick={toggleLightbox}>
        <video src={VIDEO_URL} autoPlay={true} controls={true} className='demo-lightbox-video' />
      </div>
    );
  }

  const { data } = useQuery(CASE_STUDIES_QUERY);

  const caseStudies = data ? data.allCase_studys.edges.map(node => node.node).slice(0, 3) : null;

  return (
    <div>
      {overlay}
      <Navbar links={venueNav.links} cta={venueNav.cta} passedHero={navPassedHero} />
      <div ref={heroRef} className='hero hero-lg venues-hero'>
        <div className='hero__content'>
          <h1 className='hero__title'>Focus on the entertainment.</h1>
          <h1 className='hero__title'>We'll handle your orders.</h1>
          <h3 className='hero__subtitle'>
            Enhancing the guest experience with a modern and hospitable approach to on-premises mobile ordering.
          </h3>
          <div className='venues-hero-btn-row'>
            <button onClick={toggleLightbox} className='btn btn--white demo-btn'>See the demo</button>
            <AnchorLink className='btn btn--outline' href='#get-in-touch'>Get the Noble Kit</AnchorLink>
          </div>
        </div>
      </div>
      <EasyOrder />
      <Benefits />
      <VenuesSection />
      <div id='purposeful'>
        <PurposefulVenues />
      </div>
      {caseStudies && <CaseStudies caseStudies={caseStudies} />}
      <div className='venue-quote-carousel'>
        <QuoteCarousel quotes={quotesData} />
      </div>
      <Join />
      <GetInTouch />
      <Footer />
    </div>
  );
});
