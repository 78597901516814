
import classnames from 'classnames';
import * as React from 'react';

interface CarouselPreviewProps {
  slide: any;
  selected: boolean;
  index: number;
  onClick: (index: number) => void;
}

class CarouselPreview extends React.Component<CarouselPreviewProps, {}> {
  constructor(props) {
    super(props);

    this.onClickPreviewImage = this.onClickPreviewImage.bind(this);
  }

  onClickPreviewImage() {
    this.props.onClick(this.props.index);
  }

  render() {
    const { slide, selected } = this.props;

    return (
      <div className='kit-carousel-preview-image-wrapper'>
        <div
          onClick={this.onClickPreviewImage}
          className={classnames('kit-carousel-preview-image', { 'kit-carousel-preview-image--selected': selected })}
          style={{ backgroundImage: `url(${slide.image})` }}
        />
      </div>
    );
  }
}

export default CarouselPreview;
