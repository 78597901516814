import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Routes from './routes';
import './App.css';

export const App = () => (
  <Switch>
    <Route exact path="/" component={Routes.Home} />
    <Route exact path="/about" component={Routes.About} />
    <Route exact path="/venues/case-studies" component={Routes.CaseStudies} />
    <Route exact path="/venues/case-study/:id" component={Routes.CaseStudy} />
    <Route exact path="/press" component={Routes.Press} />
    <Route exact path="/press-article/:id" component={Routes.PressArticle} />
    <Route exact path="/download" component={Routes.Download} />
    <Route exact path="/venues/kit" component={Routes.Kit} />
    <Route exact path="/preorders/:id" component={Routes.Preorders} />
    <Route exact path="/terms-of-service" component={Routes.TermsOfService} />
    <Route exact path="/privacy-policy" component={Routes.PrivacyPolicy} />
    <Route exact path="/venues" component={Routes.Venues} />
    <Route exact path="/solutions/:id" component={Routes.Solutions} />
  </Switch>
);
