
import classnames from 'classnames';
import * as React from 'react';
import './GetInTouch.scss';

declare let gtag;
declare let fbq;
declare let gtag_report_conversion;

const copy = 'Have questions about Noble or just want to say hello? Drop us a line and a member of our team will get'
           + 'back to you shortly.';

const leftIcons = [
  'stadium-icon',
  'beer-icon',
  'concert-icon',
];

const rightIcons = [
  'football-icon',
  'meal-icon',
  'theatre-icon',
];

const PREFIX = '/static/img/get-in-touch/';

const Icon = ({ icon }) => (
  <img src={PREFIX + icon + '.png'} key={icon} className={classnames(`get-in-touch__${icon}`, 'get-in-touch__icon')} />
);

const Input = ({ Tag, error, onChange, name, value, placeholder }) => (
  <Tag
    value={value}
    onChange={onChange}
    name={name}
    type='text'
    placeholder={placeholder}
    className={classnames('get-in-touch-form__input', { 'get-in-touch-form__input--error': error && !value })}
  />
);

export class GetInTouch extends React.Component<{}, any> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sent: false,
      error: false,
      data: {
        name: '',
        email: '',
        subject: '',
        message: '',
        venue: '',
        state: '',
        city: '',
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
  }

  onChangeField(e) {
    const { value, name } = e.target;

    this.setState({
      data: {
        ...this.state.data,
        [ name ]: value,
      },
    });
  }

  async onSubmit(e) {
    e.preventDefault();
    const fields = ['name', 'venue', 'city', 'state', 'email', 'subject', 'message'];
    if (!fields.every( field => this.state.data[field] )) {
      this.setState({ error: true });
      return;
    }

    this.setState({ loading: true });

    gtag('event', 'submit', {
      event_category: 'GetInTouchForm',
      event_label: 'Submit',
      transport_type: 'beacon',
      name: this.state.name,
      subject: this.state.subject,
    });

    fbq('track', 'Contact', {
      type: 'VenueContact',
      name: this.state.name,
      subject: this.state.subject,
    });

    await fetch('/api/send-email', {
      method: 'POST',
      body: JSON.stringify(this.state.data),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setState({ loading: false, sent: true });

    gtag_report_conversion();
  }

  renderButton() {
    if (this.state.loading) {
      return <p>Loading...</p>;
    } else if (this.state.sent) {
      return <p>Sent! We'll be in touch soon</p>;
    } else {
      return <button type='submit' className='btn btn--outline'>Send</button>;
    }
  }

  render() {
    const { name, venue, city, state, email, subject, message } = this.state.data;
    const { error } = this.state;

    return (
      <section id='get-in-touch' className='get-in-touch'>
        <div className='get-in-touch__left'>
          {leftIcons.map( icon => <Icon key={icon} icon={icon} /> )}
        </div>
        <form className='get-in-touch-form' onSubmit={this.onSubmit}>
          <h3 className='header--lg get-in-touch__header'>Get In Touch</h3>
          <p className='subheader'>{copy}</p>
          <Input error={error} Tag='input' name='name' placeholder='Name' onChange={this.onChangeField} value={name} />
          <Input
            error={error}
            Tag='input'
            name='venue'
            placeholder='Venue Name'
            onChange={this.onChangeField}
            value={venue}
          />
          <div className='get-in-touch-row'>
            <Input
              error={error}
              Tag='input'
              name='city'
              placeholder='Venue City'
              onChange={this.onChangeField}
              value={city}
            />
            <Input
              error={error}
              Tag='input'
              name='state'
              placeholder='Venue State'
              onChange={this.onChangeField}
              value={state}
            />
          </div>
          <Input
            error={error}
            Tag='input'
            name='email'
            placeholder='Email'
            onChange={this.onChangeField}
            value={email}
          />
          <Input
            error={error}
            Tag='input'
            name='subject'
            placeholder='Subject'
            onChange={this.onChangeField}
            value={subject}
          />
          <Input
            error={error}
            Tag='textarea'
            name='message'
            placeholder='Message'
            onChange={this.onChangeField}
            value={message}
          />
          {this.renderButton()}
        </form>
        <div className='get-in-touch__right'>
          {rightIcons.map( icon => <Icon key={icon} icon={icon} /> )}
        </div>
      </section>
    );
  }
}
