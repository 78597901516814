import * as React from 'react';
import { PreorderReminderForm } from './Preorders';
import { getVenue } from '../../core/api';

export const Preorders = ({ match }) => {
  const { id } = match.params;
  const [venue, setVenue] = React.useState(null);
  React.useEffect(() => {
    const fetch = async () => {
      const result = await getVenue(id);
      setVenue(result);
    };

    fetch();
  }, [id]);

  return <PreorderReminderForm venue={venue} />
};
