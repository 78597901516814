import * as React from 'react';
import './Download.scss';

const ANDROID_URL = 'https://play.google.com/store/apps/details?id=co.getnoble.nobleconsumer&hl=en_US';
const IOS_URL = 'https://itunes.apple.com/us/app/noble-drink-ordering-at-bars-in-boston-ma/id1211378457?mt=8';

export class Download extends React.Component<{}, { timeLeft: number }> {
  private timer;

  constructor(props) {
    super(props);

    this.state = { timeLeft: 5 };
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].classList.add('download-body');
    this.timer = setInterval(() => {
      if (this.state.timeLeft === 0) {
        const browser = navigator.userAgent.toLowerCase();
        const isAndroid = browser.indexOf('android') > -1;
        const url = isAndroid ? ANDROID_URL : IOS_URL;
        window.location.href = url;
      } else {
        this.setState({ timeLeft: this.state.timeLeft - 1 });
      }
    }, 1000);
  }

  componentWillMount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <div>
        <h1>Redirecting you to the app store...</h1>
        <p>Please wait while you're transfered to the App Store page for the Noble app.</p>
        <small>You will be transfered in {this.state.timeLeft} seconds</small>
      </div>
    );
  }
}
