
import { CarouselInjectedProps, Slide, Slider } from 'pure-react-carousel';
import * as React from 'react';
import CarouselPreview from './CarouselPreview';

interface CarouselProps extends CarouselInjectedProps {
  readonly currentSlide: number;
  slides: any[];
}

const CarouselSlide = ({ index, slide }) => (
  <Slide index={index}>
    <img className='w-100' alt={slide.title} src={slide.image} />
  </Slide>
);

class Carousel extends React.Component<CarouselProps, {}> {
  constructor(props) {
    super(props);

    this.onClickPreviewImage = this.onClickPreviewImage.bind(this);
  }

  onClickPreviewImage(index) {
    this.props.carouselStore.setStoreState({ currentSlide: index });
  }

  renderSlides() {
    const { currentSlide, slides } = this.props;
    return slides.map( (slide, index) => (
      <CarouselPreview
        key={index}
        index={index}
        onClick={this.onClickPreviewImage}
        selected={currentSlide === index}
        slide={slide}
      />
    ));
  }

  render() {
    return (
      <div className='kit-carousel'>
        <Slider>
          {this.props.slides.map( (slide, index) => <CarouselSlide key={index} index={index} slide={slide} /> )}
        </Slider>
        <div className='kit-carousel-previews'>
          {this.renderSlides()}
        </div>
      </div>
    );
  }
}

export default Carousel;
