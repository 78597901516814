
/* tslint:disable:rule1 max-line-length */
import * as React from 'react';

const svg = (className) => `
    <svg class="${className}" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="231.41" height="49.82" viewBox="0 0 231.41 49.82">
      <defs>
          <style>.cls-1,.cls-2{fill:#fff;}.cls-2{fill-rule:evenodd;}</style>
      </defs>
      <path class="cls-1" d="M14.39,2.95h.04s40.03,0,40.03,0c.88,0,1.69,.7,1.84,1.6l6.63,36.69-14.66-14.37-2.33-2.29-2.03,2.55-9.79,12.29-9.29-12.24-1.94-2.55-2.38,2.15-15.72,14.17L12.51,4.46c.18-.86,.99-1.51,1.87-1.51m0-2.95c-2.27,0-4.28,1.63-4.75,3.84L0,49.21,22.46,28.97l11.58,15.26,12.15-15.25,21.27,20.84L59.19,4.02C58.8,1.74,56.79,.02,54.48,0H14.42s-.03,0-.04,0h0Z"/>
      <g>
          <path class="cls-2" d="M231.41,26.45v2.99h-18V2.92h17.66v2.99h-14.3V14.5h12.43v2.8h-12.43v9.15h14.64Zm-46.25,2.99V2.92h3.36V26.45h14.78v2.99h-18.14Zm-27.95-23.59V14.68h8.44c.6,0,1.14-.12,1.64-.35,.5-.24,.93-.55,1.29-.95,.36-.4,.65-.87,.86-1.4,.21-.53,.32-1.1,.32-1.7s-.1-1.21-.3-1.76c-.2-.55-.47-1.02-.8-1.42-.34-.4-.75-.71-1.23-.93-.49-.23-1-.34-1.55-.34h-8.66Zm13.27,14.43c-.21-.56-.5-1.05-.86-1.47-.36-.42-.79-.76-1.29-1.01s-1.04-.37-1.64-.37h-9.48v9.11h9.22c.62,0,1.19-.12,1.72-.37,.52-.25,.98-.58,1.38-.99,.4-.41,.71-.89,.93-1.44,.23-.55,.34-1.12,.34-1.72s-.11-1.18-.32-1.74Zm3.1,5.13c-.41,.85-.97,1.57-1.68,2.17-.71,.6-1.53,1.06-2.46,1.38-.93,.32-1.94,.48-3,.48h-12.58V2.92h13.18c.92,0,1.76,.2,2.5,.6,.75,.4,1.38,.91,1.91,1.55,.52,.64,.93,1.36,1.21,2.17,.29,.81,.43,1.62,.43,2.45,0,1.32-.33,2.54-.99,3.66-.66,1.12-1.6,1.95-2.82,2.5,1.52,.45,2.72,1.27,3.6,2.48,.88,1.21,1.33,2.63,1.33,4.28,0,1.02-.2,1.95-.62,2.8Zm-51.58-5.32c.44,1.26,1.05,2.37,1.85,3.34,.8,.97,1.76,1.74,2.87,2.31,1.12,.57,2.37,.86,3.74,.86s2.69-.3,3.81-.92c1.12-.61,2.07-1.41,2.86-2.39,.78-.98,1.38-2.1,1.79-3.34,.41-1.25,.61-2.5,.61-3.77,0-1.34-.22-2.64-.65-3.9-.44-1.26-1.06-2.36-1.87-3.32-.81-.96-1.77-1.72-2.87-2.29-1.11-.57-2.33-.86-3.68-.86-1.42,0-2.69,.3-3.81,.9-1.12,.6-2.07,1.38-2.86,2.35-.78,.97-1.39,2.08-1.81,3.32-.42,1.25-.63,2.51-.63,3.81s.22,2.64,.65,3.9Zm3.32,8.4c-1.53-.76-2.84-1.77-3.94-3.02-1.09-1.26-1.94-2.69-2.54-4.31-.6-1.62-.9-3.27-.9-4.97s.32-3.46,.95-5.08c.63-1.62,1.51-3.05,2.63-4.29,1.12-1.24,2.45-2.23,3.98-2.97,1.53-.73,3.19-1.1,4.98-1.1s3.56,.39,5.08,1.18c1.52,.78,2.82,1.81,3.92,3.08,1.1,1.27,1.94,2.71,2.54,4.31,.6,1.61,.9,3.24,.9,4.91,0,1.77-.32,3.46-.95,5.08-.63,1.62-1.5,3.04-2.61,4.27-1.11,1.23-2.43,2.21-3.96,2.95-1.53,.73-3.19,1.1-4.98,1.1s-3.57-.38-5.1-1.14ZM87.99,9.2V29.43h-3.36V2.92h2.61l16.43,20.65V2.96h3.36V29.43h-2.84l-16.2-20.23Z"/>
          <g>
            <path class="cls-1" d="M85.13,44.33h-1.49l3.24-8.06h1.51l3.24,8.06h-1.49l-.74-1.88h-3.54l-.74,1.88Zm1.2-3.12h2.61l-1.31-3.34-1.3,3.34Z"/>
            <path class="cls-1" d="M103.19,38.15c-.25-.25-.56-.45-.91-.59-.35-.14-.73-.2-1.13-.2s-.77,.07-1.11,.21-.62,.34-.87,.6c-.24,.26-.43,.57-.56,.92s-.2,.75-.2,1.18,.07,.84,.2,1.2c.13,.36,.32,.68,.57,.94,.25,.27,.54,.47,.87,.61,.34,.14,.71,.21,1.11,.21,.35,0,.67-.05,.97-.16,.29-.11,.55-.27,.78-.47,.22-.21,.41-.45,.55-.74,.14-.29,.24-.61,.28-.97h-2.55v-1.1h3.8l.04,.54c0,.61-.09,1.17-.28,1.68-.19,.51-.46,.95-.8,1.32-.35,.37-.76,.66-1.24,.86-.48,.2-1,.3-1.58,.3s-1.14-.11-1.65-.32c-.51-.21-.95-.51-1.33-.88s-.67-.82-.88-1.33c-.21-.51-.31-1.06-.31-1.66s.11-1.15,.31-1.66c.21-.51,.5-.96,.88-1.33,.37-.38,.81-.67,1.32-.88,.51-.21,1.05-.31,1.65-.31s1.1,.1,1.61,.29,.97,.47,1.38,.83l-.92,.91Z"/>
            <path class="cls-1" d="M113.53,44.33h-1.57l-1.77-3.03h-1.53v3.03h-1.42v-8.06h2.97c.63,0,1.16,.1,1.61,.3,.44,.2,.79,.49,1.03,.86s.36,.82,.36,1.35c0,.56-.14,1.03-.41,1.42s-.67,.67-1.17,.85l1.91,3.27Zm-4.87-4.19h1.45c.52,0,.92-.11,1.21-.34,.29-.23,.43-.55,.43-.97s-.14-.74-.43-.97c-.29-.23-.69-.34-1.21-.34h-1.45v2.62Z"/>
            <path class="cls-1" d="M122.52,36.28v4.44c0,.78-.14,1.45-.42,2.02-.28,.57-.68,1-1.2,1.3-.52,.3-1.14,.45-1.86,.45s-1.34-.15-1.86-.45c-.52-.3-.92-.74-1.2-1.3-.28-.57-.42-1.24-.42-2.02v-4.44h1.42v4.28c0,.58,.08,1.08,.24,1.48,.16,.4,.39,.7,.7,.91,.31,.2,.68,.31,1.12,.31s.82-.1,1.13-.31c.31-.2,.54-.51,.7-.91,.16-.4,.24-.89,.24-1.48v-4.28h1.42Z"/>
            <path class="cls-1" d="M125.05,44.33v-8.06h2.94c.83,0,1.48,.18,1.95,.54,.47,.36,.7,.87,.7,1.51,0,.42-.1,.79-.3,1.1-.2,.32-.47,.55-.81,.71,.47,.13,.84,.36,1.1,.7,.26,.34,.4,.75,.4,1.24,0,.45-.11,.85-.33,1.18-.22,.34-.54,.6-.94,.79-.4,.19-.88,.28-1.42,.28h-3.29Zm1.42-4.62h1.45c.41,0,.73-.1,.97-.3,.24-.2,.36-.47,.36-.81s-.12-.6-.36-.79c-.24-.19-.57-.29-.99-.29h-1.42v2.19Zm0,3.38h1.53c.32,0,.6-.05,.84-.15s.42-.24,.55-.41c.13-.18,.2-.39,.2-.62s-.06-.43-.18-.6c-.12-.17-.29-.3-.52-.39-.22-.09-.49-.14-.79-.14h-1.62v2.32Z"/>
            <path class="cls-1" d="M133.23,44.33v-8.06h2.94c.83,0,1.48,.18,1.95,.54,.47,.36,.7,.87,.7,1.51,0,.42-.1,.79-.3,1.1-.2,.32-.47,.55-.81,.71,.47,.13,.84,.36,1.1,.7,.26,.34,.4,.75,.4,1.24,0,.45-.11,.85-.33,1.18-.22,.34-.54,.6-.94,.79-.4,.19-.88,.28-1.42,.28h-3.29Zm1.42-4.62h1.45c.41,0,.73-.1,.97-.3,.24-.2,.36-.47,.36-.81s-.12-.6-.36-.79c-.24-.19-.57-.29-.99-.29h-1.42v2.19Zm0,3.38h1.53c.32,0,.6-.05,.84-.15s.42-.24,.55-.41c.13-.18,.2-.39,.2-.62s-.06-.43-.18-.6c-.12-.17-.29-.3-.52-.39-.22-.09-.49-.14-.79-.14h-1.62v2.32Z"/>
            <path class="cls-1" d="M147.69,44.33h-1.57l-1.77-3.03h-1.53v3.03h-1.42v-8.06h2.97c.63,0,1.16,.1,1.61,.3,.44,.2,.79,.49,1.03,.86s.36,.82,.36,1.35c0,.56-.14,1.03-.41,1.42s-.67,.67-1.17,.85l1.91,3.27Zm-4.87-4.19h1.45c.52,0,.92-.11,1.21-.34,.29-.23,.43-.55,.43-.97s-.14-.74-.43-.97c-.29-.23-.69-.34-1.21-.34h-1.45v2.62Z"/>
            <path class="cls-1" d="M156.08,44.33h-1.57l-1.77-3.03h-1.53v3.03h-1.42v-8.06h2.97c.63,0,1.16,.1,1.61,.3,.44,.2,.79,.49,1.03,.86s.36,.82,.36,1.35c0,.56-.14,1.03-.41,1.42s-.67,.67-1.17,.85l1.91,3.27Zm-4.87-4.19h1.45c.52,0,.92-.11,1.21-.34,.29-.23,.43-.55,.43-.97s-.14-.74-.43-.97c-.29-.23-.69-.34-1.21-.34h-1.45v2.62Z"/>
            <path class="cls-1" d="M159.23,40.68c-.45,0-.85-.1-1.21-.3-.35-.2-.63-.48-.83-.83-.2-.35-.3-.75-.3-1.21s.1-.85,.3-1.2,.48-.63,.83-.83c.35-.2,.76-.3,1.21-.3s.85,.1,1.21,.3c.35,.2,.63,.48,.83,.83s.3,.75,.3,1.2-.1,.85-.3,1.21c-.2,.35-.48,.63-.83,.83-.35,.2-.75,.3-1.21,.3Zm0-.53c.35,0,.66-.08,.93-.23s.48-.37,.64-.64,.23-.58,.23-.93-.08-.66-.23-.93-.37-.49-.64-.65c-.27-.16-.58-.24-.92-.24s-.65,.08-.92,.24c-.27,.16-.49,.37-.64,.65s-.24,.58-.24,.93,.08,.66,.23,.93,.37,.49,.64,.64,.58,.23,.93,.23Zm1.03-.56h-.57l-.5-.98h-.32v.97h-.51v-2.51h.92c.28,0,.5,.07,.66,.21,.16,.14,.24,.33,.24,.56,0,.17-.04,.31-.12,.43-.08,.12-.2,.21-.35,.27l.55,1.05Zm-1.4-1.41h.32c.16,0,.28-.03,.36-.08,.08-.06,.12-.14,.12-.25s-.04-.19-.12-.24c-.08-.05-.2-.08-.37-.08h-.32v.66Z"/>
            <path class="cls-1" d="M174.04,43.31c-.37,.37-.82,.66-1.33,.87-.51,.21-1.08,.31-1.68,.31s-1.17-.1-1.68-.31c-.52-.21-.96-.5-1.34-.88s-.67-.82-.88-1.33c-.21-.51-.31-1.07-.31-1.67s.11-1.16,.31-1.67c.21-.51,.5-.95,.88-1.33,.38-.38,.83-.67,1.34-.88,.52-.21,1.08-.31,1.68-.31s1.16,.1,1.68,.31c.51,.21,.95,.5,1.33,.87l-.99,1c-.25-.29-.54-.52-.89-.69-.35-.16-.73-.25-1.14-.25s-.76,.07-1.09,.22c-.33,.15-.62,.35-.87,.62-.25,.27-.44,.58-.58,.93-.14,.36-.21,.75-.21,1.17s.07,.81,.21,1.17c.14,.36,.33,.67,.58,.93,.25,.27,.54,.47,.87,.62,.33,.15,.7,.22,1.09,.22,.42,0,.8-.08,1.14-.25,.35-.16,.64-.39,.89-.69l1,1Z"/>
            <path class="cls-1" d="M180.24,44.5c-.61,0-1.17-.1-1.68-.31-.51-.21-.96-.5-1.34-.88s-.67-.82-.88-1.33c-.21-.51-.31-1.07-.31-1.67s.11-1.16,.31-1.67c.21-.51,.5-.95,.88-1.33,.38-.38,.83-.67,1.34-.88,.51-.21,1.08-.31,1.69-.31s1.17,.1,1.68,.31c.51,.21,.96,.5,1.34,.88,.38,.38,.67,.82,.88,1.33,.21,.51,.31,1.07,.31,1.67s-.1,1.16-.31,1.67c-.21,.51-.5,.95-.88,1.33s-.83,.67-1.34,.88c-.51,.21-1.07,.31-1.69,.31Zm0-1.25c.4,0,.76-.07,1.1-.22,.34-.15,.63-.35,.88-.62,.25-.27,.44-.58,.58-.94,.14-.36,.21-.75,.21-1.17s-.07-.81-.21-1.17c-.14-.36-.33-.67-.58-.94s-.54-.47-.88-.62c-.34-.15-.7-.22-1.1-.22s-.76,.07-1.1,.22c-.34,.15-.63,.35-.88,.62s-.44,.58-.58,.94c-.14,.36-.21,.75-.21,1.17s.07,.81,.21,1.17c.14,.36,.33,.67,.58,.94,.25,.27,.54,.47,.88,.62,.34,.15,.7,.22,1.1,.22Z"/>
            <path class="cls-1" d="M195.12,44.33h-1.42v-6.04l-2.24,4.69h-1.14l-2.23-4.66v6.02h-1.42v-8.06h1.8l2.42,5.15,2.41-5.15h1.82v8.06Z"/>
            <path class="cls-1" d="M197.65,44.33v-8.06h2.66c.67,0,1.25,.11,1.74,.32s.86,.52,1.12,.92,.39,.87,.39,1.42-.13,1.02-.39,1.42c-.26,.4-.63,.71-1.11,.93-.48,.22-1.06,.33-1.73,.33h-1.26v2.72h-1.42Zm1.42-3.96h1.11c.62,0,1.1-.13,1.43-.38,.33-.25,.5-.6,.5-1.06s-.17-.8-.5-1.05-.81-.37-1.43-.37h-1.11v2.85Z"/>
            <path class="cls-1" d="M206.15,44.33h-1.49l3.24-8.06h1.51l3.24,8.06h-1.49l-.74-1.88h-3.54l-.74,1.88Zm1.2-3.12h2.61l-1.31-3.34-1.3,3.34Z"/>
            <path class="cls-1" d="M221.86,44.33h-1.6l-4.2-5.86v5.86h-1.42v-8.06h1.6l4.2,5.87v-5.87h1.42v8.06Z"/>
            <path class="cls-1" d="M228.26,44.33h-1.42v-3.44l-3.07-4.61h1.72l2.06,3.38,2.07-3.38h1.72l-3.08,4.62v3.43Z"/>
          </g>
      </g>
    </svg>

`;

export const Logo = ({ className }) => (
  <div dangerouslySetInnerHTML={{__html: svg(className) }} />
);
