import fetch from 'isomorphic-fetch';

export const getVenue = async (id) => {
  const resp = await fetch(`/api/venues/${id}`);
  return resp.json();
};

export const submitPreorder = async (preorder) => {
  const resp = await fetch(`/api/preorder-reminder`, {
    method: 'POST',
    body: JSON.stringify(preorder),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  if (!resp.ok) {
    const json = await resp.json();
    throw json;
  }

  return resp.json();
};
