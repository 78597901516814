
import * as React from 'react';
// import "./index.scss";

export default () => (
  <div>
    <div className='large-row two-col-lockup two-col-lockup__vertical--align'>
      <div className='two-col-lockup__copy' id='about'>
        <h1 className='two-col-lockup__title'>
          Easy order. <br className='hidden--md'/> Easy pay.
        </h1>
        <p>
          Noble allows you to order and pay for food and drinks at your favorite venues and events without missing the
          fun. No more waiting for your server or getting stuck in long lines. Order from your phone and you’ll be
          notified when your order is ready for pickup or delivery.
        </p>
      </div>

      <div className='two-col-lockup__image two-col-lockup__image--centered two-col-lockup__vertical--align'>
        <img alt='Noble: Easy Order, Easy Pay' className='img' src='/static/img/easy-order-easy-pay-iphones@2x.png' />
      </div>
    </div>
    <div className='large-row two-col-lockup gradient-background--0deg'>

      <div className='two-col-lockup__image two-col-lockup__image--left hidden--md'>
        <div className='gradient-background--45deg'>
          <img alt='The Noble App' className='img--centered-vertical' src='/static/img/venue-list--desktop.png' />
        </div>
      </div>

      <div className='two-col-lockup__image two-col-lockup__image--centered show--md'>
        <img alt='The Noble App' src='/static/img/venue-list--mobile.png' />
      </div>

      <div className='two-col-lockup__copy'>
        <h1 className='two-col-lockup__title'>
          Ordering should be easy, no matter where you are
        </h1>

        <p>
          Whether you’re at a theater, stadium, concert, or another exciting event or venue, Noble is there to help
          you make the most of your experience. Let us take care of the ordering so you never miss the fun.
        </p>
      </div>
    </div>
  </div>
);
