
import * as React from 'react';

export default ({ tweet }) => (
  <div className='tweet'>
    <div className='tweet__profile-picture'>
      <img role='presentation' src='/static/img/footer/noble-twitter-icon.png' />
    </div>
    <div className='tweet__body'>
      <a href='https://twitter.com/OrderWithNoble' className='tweet__name'>
        Noble
        <span className='tweet__handle'>@OrderWithNoble</span>
      </a>
      <div className='tweet__copy'>
        {tweet}
      </div>
    </div>
  </div>
);
