
export const consumerNav = {
  links: [
    {
      title: 'Home',
      href: '#',
    },
    {
      title: 'Venues',
      href: '/venues',
    },
    {
      title: 'Benefits',
      href: '#benefits',
    },
  ],
  cta: {
    title: 'Get the Noble app',
    href: '/download',
  },
};

export const venueNav = {
  links: [
    {
      title: 'Venue Home',
      href: '/venues',
    },
    {
      title: 'Noble Experience',
      href: '/venues#experience',
    },
    {
      title: 'Industries',
      href: '/venues#purposeful',
    },
    {
      title: 'Case Studies',
      href: '/venues/case-studies',
    },
  ],
  cta: {
    title: 'Get the Noble Kit',
    href: '/venues#get-in-touch',
  },
};
