import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { RichText } from 'prismic-reactjs';
import classnames from 'classnames';
import { consumerNav } from '../../constants/NavLinks';
import { Footer, Navbar, GetInTouch } from '../../components';
import { withNavControl } from '../../hocs';
import './Solutions.scss';

const isEmpty = columnContent => {
  return (
    columnContent[0] &&
    !(
      columnContent[0].text ||
      columnContent[0].url
    )
  );
};

const PageColumn = ({ columnContent, className }) => {
  if (!columnContent || isEmpty(columnContent)) {
    return null;
  }

  return (
    <div className={classnames("solutions-col", className)}>
      {RichText.render(columnContent)}
    </div>
  )
}

const PageRow = ({ contentRow }) => {
  return (
    <div className="solutions-row">
      <PageColumn className='solutions-col-left' columnContent={contentRow.left_content} />
      <PageColumn className='solutions-col-center' columnContent={contentRow.center_content} />
      <PageColumn className='solutions-col-right' columnContent={contentRow.right_content} />
    </div>
  )
};

const getHeroStyles = page => {
  const url = page.header_background_image && page.header_background_image.url;
  return (url) ? { backgroundImage: `url(${url})` } : {};
};

const PageContent = ({ heroRef, page }) => {
  return (
    <div>
      <div ref={heroRef} className='solutions-hero hero' style={getHeroStyles(page)}>
        <div className='hero__content'>
          <h1 className='hero__title'>{RichText.asText(page.title)}</h1>
          <h3 className='hero__subtitle'>
            {RichText.asText(page.subtitle)}
          </h3>
        </div>
      </div>
      <article className='solutions-content'>
        {page.content.map((row, i) => <PageRow key={i} contentRow={row} />)}
      </article>
    </div>
  );
}

const SOLUTION_QUERY = gql`
query SolutionQuery($id: String) {
  allSolutions_pages(uid: $id) {
    edges {
      node {
        title
        subtitle
        header_background_image
        content {
          left_content
          center_content
          right_content
        }
      }
    }
  }
}
`;

export const Solutions = withNavControl(({ client, match, heroRef, navPassedHero }) => {
  const { id } = match.params;
  const { data } = useQuery(SOLUTION_QUERY, {
    variables: id,
  });
  
  const page = data ? data.allSolutions_pages.edges.map(node => node.node)[0] : null;

  return(
    <div>
      <Navbar links={consumerNav.links} cta={consumerNav.cta} passedHero={navPassedHero} />
      {page && <PageContent heroRef={heroRef} page={page} />}
      <GetInTouch />
      <Footer />
    </div>
  );
});
