
import * as React from 'react';
import MobileVenueCarousel from './MobileVenueCarousel';
import './Venues.scss';

const venues = require('./data.json');

export default () => (
  <section className='venues-list'>
    <div className='venues-list--full'>
      {venues.map( venue => <img key={venue.name} src={`/static/img/venues/logos/${venue.logo}`} alt={venue.name} /> )}
    </div>
    <div className='venues-list--mobile'>
      <MobileVenueCarousel venues={venues} />
    </div>
  </section>
);
