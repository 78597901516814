
import * as React from 'react';
import './Join.scss';

export default class Join extends React.PureComponent<{}, { phone: string }> {
  constructor(props) {
    super(props);

    this.state = { phone: '' };
  }

  onChangePhone = e => {
    this.setState({ phone: e.target.value });
  }

  onClickJoin = async () => {
    await fetch('/api/send-app-link', {
      method: 'POST',
      body: JSON.stringify({
        To: this.state.phone,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  render() {
    return (
      <div className='large-row join-noble' id='download'>
        <div className='join-noble__content'>
          <h1 className='join-noble__title'>
            Enjoy every moment.
            <div className='join-noble__break' />
            Join the Noble life.
          </h1>
          <div className='join-noble__copy'>
            <p>
              Don’t let life pass you by. Download the Noble app and begin making the most of your time out.
            </p>
            <p className='hidden--md'>
              Enter your phone number to get an app link sent to your phone.
            </p>
          </div>
          <div className='join-noble__phone'>
            <input type='tel' onChange={this.onChangePhone} name='Phone number input' placeholder='555-555-5555' />
            <button onClick={this.onClickJoin} className='btn btn-attached--right btn--black'>Send</button>
          </div>

           <div className='join-noble__btn'>
             <a className='btn btn--outline' href='/download'>Get the Noble app</a>
           </div>
        </div>
        <div className='join-noble__img'>
          <img role='presentation' className='hidden--md' src='/static/img/join-phones@2x.png' />
          <img role='presentation' className='show--sm' src='/static/img/join-phones--mobile.png' />
        </div>
      </div>
    );
  }
}
