
import { RichText } from 'prismic-reactjs';
import * as React from 'react';
import LinkResolver from '../../core/linkResolver';

const getPreviewImageStyles = caseStudy => {
  return caseStudy.list_preview_image && { backgroundImage: `url(${caseStudy.list_preview_image.url})` };
};

const getLink = caseStudy => `/venues/case-study/${caseStudy._meta.uid}`;

const CaseStudy = ({ caseStudy }) => (
  <div className='venue-case-study'>
    <a href={getLink(caseStudy)} style={getPreviewImageStyles(caseStudy)} className='venue-case-study__link'>
      <span className='venue-case-study__name'>{RichText.asText(caseStudy.title, LinkResolver)}</span>
    </a>
  </div>
);

export default ({ caseStudies }) => (
  <section id='case-studies' className='venue-case-studies'>
    <h2 className='header header--lg header--purple'>Case Studies</h2>
    <p className='subheader'>
      Entertainment venues of all sizes are winning with Noble. Check out some of the recent success the Noble network
      has had and the features available for each venue type.
    </p>
    <div className='container venue-case-studies-list'>
      {caseStudies.map( (caseStudy, index) => <CaseStudy caseStudy={caseStudy} key={index} /> )}
    </div>
    <a href='/venues/case-studies' className='btn btn--purple-gradient venue-case-studies-btn'>See all case studies</a>
  </section>
);
